import { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useAuthAdminUserContext } from "../../contexts/AuthAdminUserContext";

const AuthenticatedAdminUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authAdminUser = useAuthAdminUserContext().authAdminUser;
  const getCurrentUser = useAuthAdminUserContext().getCurrentUser;
  const [isShowable, setIsShowable] = useState(false);

  useEffect(() => {
    (async () => {
      // stateから認証を確認
      if (authAdminUser) {
        setIsShowable(true);
        return;
      }
      // cookieから認証を確認
      const res = await getCurrentUser();
      if (res) {
        setIsShowable(true);
        return;
      }
      // ログインしていない場合はloginページへリダイレクト
      //  - ログイン後に復帰するURLを渡す
      navigate(`/admin/login?prev_url=${encodeURI(location.pathname)}`);
    })();
  }, []);

  return <>{isShowable && <Outlet />}</>;
};
export default AuthenticatedAdminUser;
