import * as yup from "yup";

yup.setLocale({
  mixed: {
    default: "不正な入力です",
    required: "入力必須項目です",
  },
  string: {
    email: "メールアドレス形式である必要があります",
    min: (prm) => `${prm.min}文字以上で入力してください`,
    max: (prm) => `${prm.max}文字以下で入力してください`,
  },
});

export default yup;
