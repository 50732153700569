import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Grid,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SignUpFormData, SignUpSchema } from "./schema/SignUpSchema";

import { ButtonCaption } from "../utils/Constants";

import { styles } from "./style/commonStyles";
import LayoutWithMenu from "./LayoutWithMenu";
import { HeadlineWithLogo } from "./parts/HeadlineWithLogo";

import { Link as RouterLink } from "react-router-dom";
import { useAuthUserContext } from "../contexts/AuthUserContext";

const SignUp = () => {
  const signUp = useAuthUserContext().signUp;

  const [sentMail, setSentMail] = useState(false);

  const onSubmit: SubmitHandler<SignUpFormData> = (data) => {
    const email = data.email;
    const password = data.password;
    const params: SignUpFormData = {
      email,
      password,
      confirm_success_url: `${process.env.REACT_APP_ROOT_URL}/login?email=${encodeURIComponent(email)}`,
    };

    signUp(params, () => {
      console.log("サインアップ!");
      setSentMail(true);
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpFormData>({
    resolver: yupResolver(SignUpSchema),
  });

  return (
    <LayoutWithMenu hideNavLinks={true}>
      {sentMail ? (
        <>
          <Typography sx={{ m: 3 }} variant="subtitle2" align="center">
            新規登録ありがとうございます。
            <br />
            登録依頼を受け付けました。
            <br />
            数分以内にメールを送信いたしますので、内容をご確認ください。
          </Typography>
        </>
      ) : (
        <>
          <HeadlineWithLogo pageTitleName="新規登録" />
          <Stack spacing={4}>
            <Stack spacing={4} sx={{ pt: 3 }}>
              <TextField
                required
                label="メールアドレス"
                InputLabelProps={{ shrink: true }}
                {...register("email")}
                error={"email" in errors}
                helperText={errors.email?.message}
                placeholder="メールアドレスを入力してください"
                sx={styles.TextField}
              />

              <TextField
                required
                label="パスワード"
                type="password"
                InputLabelProps={{ shrink: true }}
                {...register("password")}
                error={"password" in errors}
                helperText={errors.password?.message}
                placeholder="パスワードを入力してください"
                sx={styles.TextField}
              />

              <Typography
                variant="caption"
                color="textSecondary"
                component="span"
                style={{ marginTop: "0.2em", marginLeft: "1em" }}
              >
                ※ 英数小文字8文字以上
              </Typography>
            </Stack>

            <Stack spacing={2}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="column"
              >
                <Grid item xs={2}>
                  <Button
                    sx={styles.buttonSingle}
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {ButtonCaption.signup}
                  </Button>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="column"
              >
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <Box>
                      <Link
                        component={RouterLink}
                        to="/login"
                        variant="caption"
                      >
                        ログインする
                      </Link>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </>
      )}
    </LayoutWithMenu>
  );
};
export default SignUp;
