import client from "lib/api/client";
import Cookies from "js-cookie";
import { AxiosRequestConfig, AxiosHeaders } from "axios";

import {
  SignUpParams,
  SignInParams,
  PasswordResetParams,
  PasswordUpdateParams,
} from "interfaces/index";
import {
  PasswordResetFormData,
  PasswordResetSchema,
} from "../../components/schema/PasswordResetSchema";

// サインアップ（新規アカウント作成）
export const signUp = (params: SignUpParams) => {
  return client.postRetrieveDataAndHeaders("facility_staff_auth", params);
};

// サインイン（ログイン）
export const signIn = (params: SignInParams) => {
  return client.postRetrieveDataAndHeaders(
    "facility_staff_auth/sign_in",
    params,
  );
};

// サインアウト（ログアウト）
export const signOut = () => {
  return client.delete("facility_staff_auth/sign_out", {
    headers: {
      "access-token": Cookies.get("_f_access_token"),
      client: Cookies.get("_f_client"),
      uid: Cookies.get("_f_uid"),
    },
  });
};

// パスワードリセット
export const passwordReset = (params: PasswordResetFormData) => {
  return client.post("facility_staff_auth/password", params);
};

// パスワード更新
export const passwordUpdate = (
  params: PasswordUpdateParams,
  options: AxiosRequestConfig,
) => {
  return client.put("facility_staff_auth/password", params, options);
};

// 認証済みのユーザーを取得
export const getCurrentUser = () => {
  if (
    !Cookies.get("_f_access_token") ||
    !Cookies.get("_f_client") ||
    !Cookies.get("_f_uid")
  )
    return;
  return client.get("/facility_staff_auth/sessions", {
    headers: {
      "access-token": Cookies.get("_f_access_token"),
      client: Cookies.get("_f_client"),
      uid: Cookies.get("_f_uid"),
    },
  });
};
