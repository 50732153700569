import { useEffect, useState, useRef, createRef, RefObject } from "react";
import { Snackbar } from "./parts/Snackbar";
import LayoutWithMenu from "./LayoutWithMenu";
import ImageModal from "./parts/ImageModal";

import { AlertColor, Grid, Button, Typography, Box } from "@mui/material";

import { getReservationListByUser } from "../lib/api/reservation";

import { Reservation } from "../interfaces/index";
import { useAuthUserContext } from "../contexts/AuthUserContext";
import { styles as commonStyles } from "./style/commonStyles";
import { formattedDatetime } from "../utils/helper";
import mime from "mime";
import { PDFThumbnail } from "./atoms/PDFThumbnail";

const ReceiptDetailList = () => {
  const authUser = useAuthUserContext().authUser;
  const [reservations, setReservations] = useState<Reservation[]>([]);

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage] = useState<string>("");
  const [severity] = useState<AlertColor>("success");

  // 診療明細表示領域制御用
  const receiptRef = useRef<RefObject<HTMLDivElement>[]>([]);
  const [displayIndex, setDisplayIndex] = useState<number>(-1);

  // MIMEタイプ判定関数
  const isPdfFile = (url: string) => {
    const mimeType = mime.getType(url.split("?")[0]); // クエリパラメータを除去してMIMEタイプを判定
    return mimeType === "application/pdf";
  };

  useEffect(() => {
    getReservationListByUserData();
  }, [authUser]);

  // 予約情報取得
  const getReservationListByUserData = async () => {
    if (!authUser) {
      return;
    }

    try {
      const response = await getReservationListByUser(authUser.id.toString());
      setReservations(response.data);
      createRefObject(response.data);
    } catch (error) {
      console.error("予約一覧の取得に失敗しました", error);
    }
  };

  //  診療明細表示部への参照を作る
  const createRefObject = (reservationList: Reservation[]) => {
    reservationList.map((_, i) => {
      receiptRef.current[i] = createRef<HTMLDivElement>();
    });
  };

  // style定義
  const styles = {
    deleteIcon: {
      position: "absolute",
      top: 10,
      right: 0,
    },
    previewImageGrid: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      position: "relative",
    },
    ...commonStyles,
    GridContainer: {
      borderBottom: 1,
      borderColor: "lightGrey",
      py: 1,
      fontSize: 16,
      textDecoration: "none",
      color: "#000000",
      paddingTop: 2,
      paddingBottom: 2,
    },
    GridItem: {
      alignContent: "center",
    },
  };

  // Modal表示管理
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalImgSrc, setModalImgSrc] = useState<string>("");

  // 診療明細表示領域開閉
  const openCloseReceiptArea = (index: number) => {
    if (displayIndex === index) return setDisplayIndex(-1);
    setDisplayIndex(index);
  };

  return (
    <LayoutWithMenu pageTitle={`診療明細一覧 (${authUser?.name} さま)`}>
      {reservations.map(
        (reservation, index) =>
          reservation.receiptFiles.length > 0 && ( // 診療明細なしの医療機関予約は表示されない
            <Grid
              container
              key={index}
              sx={{
                ...styles.GridContainer,
                flexDirection: "column",
              }}
            >
              <Box display={"flex"}>
                <Grid container item xs={7} sx={styles.GridItem}>
                  <div>
                    <p>
                      {reservation.reservationTime
                        ? formattedDatetime(reservation.reservationTime)
                        : ""}
                    </p>
                    <p>{reservation.facility.name}</p>
                  </div>
                </Grid>

                {/* クリックしたら診療明細情報を表示する */}
                <Grid container item xs={2} sx={styles.GridItem}>
                  <Button onClick={() => openCloseReceiptArea(index)}>
                    {`${reservation.receiptFiles?.length}枚`}
                  </Button>
                </Grid>
              </Box>

              {/* 診療明細表示部 */}
              <div
                ref={receiptRef.current[index]}
                style={
                  displayIndex === index
                    ? {
                        height:
                          receiptRef.current[index].current?.scrollHeight || 0,
                      }
                    : { height: "0px", overflow: "hidden" }
                }
              >
                <Grid container spacing={2}>
                  {reservation.receiptFiles.map((file, index) => (
                    <Grid item xs={6} md={4} sx={styles.GridItem} key={index}>
                      <Box
                        onClick={() => {
                          if (isPdfFile(file.url)) {
                            window.open(file.url, "_blank"); // PDFは新しいウィンドウで開く
                          } else {
                            setModalImgSrc(file.url); // 画像はモーダルに表示
                            setModalOpen(true);
                          }
                        }}
                        sx={{
                          width: "100%",
                          height: "100%",
                          border: "1px solid #ddd",
                          overflow: "hidden",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#f5f5f5",
                        }}
                      >
                        {isPdfFile(file.url) ? (
                          <PDFThumbnail filePath={file.url} />
                        ) : (
                          <img
                            src={file.url}
                            alt={`診療明細 ${index + 1}`}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
          ),
      )}

      <ImageModal
        open={modalOpen}
        setOpen={setModalOpen}
        imgSrc={modalImgSrc}
      />

      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
};

export default ReceiptDetailList;
