import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Button, AlertColor, Typography, Stack } from "@mui/material";

import {
  getPrescriptionByPharmacyReservation,
  createPrescriptions,
  updatePrescriptions,
  deletePrescriptionFile,
  getReservationWithFacility,
} from "../lib/api/reservation";

import LayoutWithMenu from "./LayoutWithMenu";

import { styles as commonStyles } from "./style/commonStyles";
import CustomDialog from "./parts/CustomDialog";
import FileUploader from "./parts/FileUploader";
import { Snackbar } from "./parts/Snackbar";
import { ButtonCaption } from "../utils/Constants";
import { randomFileName } from "../utils/helper";
import { Reservation } from "../interfaces";
import { formattedDatetime } from "../utils/helper";
import { useAuthUserContext } from "../contexts/AuthUserContext";
import { useHistoryContext } from "contexts/HistoryContext";

const styles = {
  ...commonStyles,
  PrescriptionBoxStyle: {
    mt: 2,
    py: 1,
    border: 1,
    borderColor: "lightGrey",
  },
  prescriptionSendButton: {
    width: "95%",
    height: 40,
    mx: 2,
    backgroundColor: "orange",
  },
  paragraphStyle: {
    fontSize: "16px",
    color: "#171C1F",
    fontFamily: "Roboto",
  },
};

// TODO: あとで共通化
class PrescriptionFormData {
  id: number = 0;
  user_id: number = 0;
  clinic_reservation_id: number = 0;
  pharmacy_reservation_id: number = 0;
  pharmacy_id: number = 0;
  prescription_files: File[] = [];
  status: number = 0;
}

const PrescriptionEdit = () => {
  // 遷移パラメータ
  const navigation = useNavigate();
  const { reservation_id: reservationId } = useParams<{
    reservation_id: string;
  }>();

  const [prescription, setPrescription] = useState<PrescriptionFormData>(
    new PrescriptionFormData(),
  );
  const [prescriptionFiles, setPrescriptionFiles] = useState<File[]>([]);
  const [prescriptionURLs, setPrescriptionURLs] = useState<string[]>([]);
  const [reservation, setReservation] = useState<Reservation>();
  const authUser = useAuthUserContext().authUser;
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");

  const { previousPage } = useHistoryContext();
  const canGoBack = previousPage !== null;

  // dialog用
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<string>("");

  useEffect(() => {
    displayData();
  }, [reservationId]);

  //　初期データ表示内容取得＆設定
  const displayData = async () => {
    if (!reservationId) {
      return;
    }

    await getPrescriptionData(Number(reservationId));
    await fetchReservation(Number(reservationId));
  };

  // 初期表示
  const fetchReservation = async (reservationId: number) => {
    if (!authUser) {
      return;
    }

    try {
      const response = await getReservationWithFacility(
        reservationId.toString(),
      );
      setReservation(response.data);
    } catch (error) {
      console.error("予約の取得に失敗しました", error);
    }
  };

  // 処方せん情報取得
  const getPrescriptionData = async (reservationId: number) => {
    try {
      const result = await getPrescriptionByPharmacyReservation(reservationId);
      setPrescription(result.data);
      if (result.data) {
        setPrescriptionURLs(
          result.data.prescriptionFiles.map((data: any) => {
            return data?.url;
          }),
        );
      }
    } catch (error) {
      console.log(error);
      setSnackbarProps("error", "処方せん情報の取得に失敗しました", true);
    }
  };

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (
    severity: AlertColor,
    message: string,
    isOpen: true,
  ) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  };

  // 処方せん情報登録実行
  const sendPrescription = async () => {
    if (!authUser || !reservationId) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("prescription[user_id]", authUser.id.toString());
      formData.append("prescription[pharmacy_reservation_id]", reservationId);
      // 複数画像の登録
      prescriptionFiles.forEach((file) => {
        formData.append(
          "prescription[prescription_files][]",
          file,
          randomFileName(file.name),
        );
      });

      // 処方せん送信実行
      if (prescription && prescription.id) {
        await updatePrescriptions(prescription.id, formData);
      } else {
        await createPrescriptions(formData);
      }
      // 情報の際取得と画像クリア
      await getPrescriptionData(Number(reservationId));
      setSnackbarProps("success", "処方せんが正常に登録されました", true);
    } catch (error) {
      console.error(error);
      setSnackbarProps("error", "処方せんの登録に失敗しました", true);
    } finally {
      // 画像クリアはスナックバーの表示後に行う
      setPrescriptionFiles([]);
    }
  };

  const handleDeleteFile = async (fileName: string) => {
    try {
      // 処方せんは必須のため、全件削除は許可しない
      if (prescriptionURLs.length === 1) {
        setDialogContent(
          "処方せんは必須のため、全てを削除することはできません",
        );
        setIsDialogOpen(true);
        return;
      }

      await deletePrescriptionFile(prescription.id, fileName);
      // 削除に成功したら、表示されているファイルリストから削除
      const updatedURLs = prescriptionURLs.filter(
        (url) => !url.includes(fileName),
      );
      setPrescriptionURLs(updatedURLs);
      setSnackbarProps("success", "処方せん画像が削除されました", true);
    } catch (error) {
      console.error(error);
      setSnackbarProps("error", "処方せん画像の削除に失敗しました", true);
    }
  };

  return (
    <LayoutWithMenu>
      <Typography
        sx={{ ...styles.TypographyPageTitle, mt: 5, textAlign: "left" }}
      >
        処方せん登録
      </Typography>
      {reservation && (
        <>
          <p style={styles.paragraphStyle}>
            予約 : {formattedDatetime(reservation.reservationTime)}
          </p>
          <p style={styles.paragraphStyle}>{reservation.facility.name}</p>
        </>
      )}
      <FileUploader
        files={prescriptionFiles}
        setImages={setPrescriptionFiles}
        isSingleSelect={false}
        imagesURL={prescriptionURLs}
        setImagesURL={setPrescriptionURLs}
        onDeleteFile={handleDeleteFile}
        buttonText={"処方せんを選択する"}
      />

      <Stack gap={2} sx={{ mt: 2 }} direction={"row"}>
        {canGoBack && (
          <Button
            sx={{
              ...styles.buttonSingle,
              color: "#70787C",
              borderColor: "#70787C",
              "&:hover": {
                backgroundColor: (theme) => theme.palette.action.hover,
                borderColor: "#5a6169",
                color: "#5a6169",
              },
              width: "100%",
            }}
            variant="outlined"
            onClick={() => navigation(-1)}
          >
            {ButtonCaption.back}
          </Button>
        )}
        <Button
          sx={{
            backgroundColor: "#87D1EA",
            boxShadow: "none",
            fontSize: { xs: "0.75rem", sm: "0.875rem" },
            fontWeight: 400,
            "&:hover": {
              backgroundColor: "#5cb9d3",
              boxShadow: "none",
            },
            width: "100%",
          }}
          variant="contained"
          disabled={prescriptionFiles.length === 0}
          onClick={() => sendPrescription()}
        >
          {"登録する"}
        </Button>
      </Stack>

      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />

      <CustomDialog
        isOpen={isDialogOpen}
        content={dialogContent}
        isHiddenCancel={true}
        onConfirm={() => setIsDialogOpen(false)}
      />
    </LayoutWithMenu>
  );
};
export default PrescriptionEdit;
