import { useState } from "react";
import {
  Button,
  Stack,
  Grid,
  TextField,
  Link,
  Typography,
  AlertColor,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PasswordUpdateFormData,
  PasswordUpdateSchema,
} from "../schema/PasswordUpdateSchema";

import { Snackbar } from "../parts/Snackbar";
import { ButtonCaption, Sex } from "../../utils/Constants";

import LayoutWithMenu from "../LayoutWithMenu";

import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useAuthAdminUserContext } from "../../contexts/AuthAdminUserContext";

const AdminUserPasswordUpdate = () => {
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const headers = {
    "access-token": searchParams.get("access-token"),
    client: searchParams.get("client"),
    uid: searchParams.get("uid"),
  };

  const passwordUpdate = useAuthAdminUserContext().passwordUpdate;

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");

  const [updated, setUpdated] = useState(false);

  const onSubmit: SubmitHandler<PasswordUpdateFormData> = (data) => {
    const password = data.password;
    const password_confirmation = data.password_confirmation;
    if (!(password && password_confirmation)) {
      console.log(
        "TODO: validation実装: password or password_confirmation is empty!",
      );
      return false;
    }
    const params: PasswordUpdateFormData = { password, password_confirmation };

    passwordUpdate(params, { headers }, () => {
      setSnackbarProps("success", "パスワードが更新されました", true);
      setUpdated(true);
    });
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PasswordUpdateFormData>({
    resolver: yupResolver(PasswordUpdateSchema),
  });

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (
    severity: AlertColor,
    message: string,
    isOpen: true,
  ) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  };

  return (
    <LayoutWithMenu pageTitle={"パスワードの再設定"} hideNavLinks={true}>
      {updated ? (
        <>
          <Typography sx={{ m: 3 }} variant="subtitle2" align="center">
            パスワードを更新しました。
          </Typography>
          <Link component={RouterLink} to="/admin/login" variant="body2">
            ログインページへ
          </Link>
        </>
      ) : (
        <>
          <Typography sx={{ m: 3 }} variant="subtitle2" align="center">
            新しいパスワードを登録してください。
          </Typography>
          <Stack spacing={3} sx={{ pt: 3 }}>
            <TextField
              required
              label="パスワード"
              type="password"
              InputLabelProps={{ shrink: true }}
              {...register("password")}
              error={"password" in errors}
              helperText={errors.password?.message}
            />

            <TextField
              required
              label="パスワード（確認）"
              type="password"
              InputLabelProps={{ shrink: true }}
              {...register("password_confirmation")}
              error={"password_confirmation" in errors}
              helperText={errors.password_confirmation?.message}
            />

            <Grid container justifyContent="end" spacing={2}>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  size="medium"
                  onClick={handleSubmit(onSubmit)}
                >
                  {ButtonCaption.send}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  size="medium"
                  onClick={() => navigate(-1)}
                >
                  {ButtonCaption.cancel}
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </>
      )}
      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
};
export default AdminUserPasswordUpdate;
