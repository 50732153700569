import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Stack,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  AlertColor,
  FormHelperText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserFormData, UserSchema } from "./schema/UserSchema";

import client from "../lib/api/client";
import { Snackbar } from "./parts/Snackbar";
import { ButtonCaption, Sex } from "../utils/Constants";

import LayoutWithMenu from "./LayoutWithMenu";

import { useAuthUserContext } from "../contexts/AuthUserContext";
import { styles } from "./style/commonStyles";

const POSTAL_CODE_REGEX = /^\d{7}$/;

const UserEdit = () => {
  const authUser = useAuthUserContext().authUser;
  const navigation = useNavigate();

  const [userId, setUserId] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");

  const [data, setData] = useState<UserFormData>(new UserFormData());

  const buttonStyle = {
    minWidth: 120,
    width: 140,
    height: 35,
    boxShadow: "none",
    padding: "4px 16px",
    borderRadius: "3px",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.10000000149011612px",
    textAlign: "center",
    color: "#003642",
  };

  const cancelButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#FFFFFF",
    border: "1px solid #87D1EA",
    "&:hover": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
    },
  };

  const updateButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#87D1EA",
    border: "1px solid #87D1EA",
    "&:hover": {
      backgroundColor: "#1aa3c9",
      boxShadow: "none",
    },
  };

  useEffect(() => {
    (async () => {
      const userId = authUser?.id.toString() || "";
      const response = await initDisplayData(userId);
      setUserId(userId);
      setData(response);
    })();
  }, []);

  useEffect(() => {
    reset(data);
  }, [data]);

  // 初期表示データ取得
  const initDisplayData = async (userId: string) => {
    try {
      const result = await client.get(`users/${userId}`);
      const data = result.data;
      return {
        name: data.name,
        kana: data.kana,
        sex: data.sex,
        birth: data.birth,
        postalCode: data.postalCode,
        prefecture: data.prefecture,
        address1: data.address1,
        address2: data.address2,
        address3: data.address3,
        phone: data.phone,
      } as UserFormData;
    } catch (error) {
      setSnackbarProps("error", "ユーザー情報の取得に失敗しました", true);
      console.log(error);
      return new UserFormData();
    }
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: "all",
    resolver: yupResolver(UserSchema),
    defaultValues: {
      sex: data.sex,
    },
  });

  // 郵便番号入力時の処理
  const handlePostalCodeFetch = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const postalCode = event.target.value;

    if (!POSTAL_CODE_REGEX.test(postalCode)) {
      setValue("prefecture", "");
      setValue("address1", "");
      setValue("address2", "");
      return;
    }

    const response = await client.get(`postal_codes/${postalCode}`);

    if (!response.data) {
      setSnackbarProps("error", response.message, true);
      return;
    }

    const { address1, address2, address3 } = response.data;

    try {
      setValue("prefecture", address1);
      setValue("address1", address2);
      setValue("address2", address3);
    } catch (error) {
      setSnackbarProps("error", "住所の取得に失敗しました", true);
    }
  };

  // フォーム送信時の処理
  const onSubmit: SubmitHandler<UserFormData> = async (data) => {
    // ユーザー情報更新
    try {
      const res = await client.put(`users/${userId}`, data);
      console.log({ res });
      setSnackbarProps("success", "ユーザー情報が更新されました", true);
    } catch (error) {
      setSnackbarProps("error", "ユーザー情報の更新に失敗しました", true);
    }
  };

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (
    severity: AlertColor,
    message: string,
    isOpen: true,
  ) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  };

  return (
    <LayoutWithMenu pageTitle={"登録情報変更"}>
      <Stack spacing={3} sx={{ pt: 3 }}>
        <TextField
          required
          label="氏名"
          InputLabelProps={{ shrink: true }}
          {...register("name")}
          error={"name" in errors}
          helperText={errors.name?.message}
        />

        <TextField
          required
          label="氏名(カナ)"
          InputLabelProps={{ shrink: true }}
          {...register("kana")}
          error={"kana" in errors}
          helperText={errors.kana?.message}
        />

        <Controller
          name="sex"
          control={control}
          render={({ field }) => (
            <FormControl error={"sex" in errors}>
              <RadioGroup row aria-label="sex" value={field.value}>
                <FormControlLabel
                  value={Sex.male}
                  control={<Radio />}
                  label="男性"
                  {...register("sex")}
                />
                <FormControlLabel
                  value={Sex.female}
                  control={<Radio />}
                  label="女性"
                  {...register("sex")}
                />
              </RadioGroup>
              {"sex" in errors && (
                <FormHelperText>{errors.sex?.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />

        <TextField
          required
          type="date"
          label="生年月日"
          InputLabelProps={{ shrink: true }}
          {...register("birth")}
          error={"birth" in errors}
          helperText={errors.birth?.message}
        />

        <TextField
          required
          label="郵便番号"
          InputLabelProps={{ shrink: true }}
          {...register("postalCode")}
          error={"postalCode" in errors}
          helperText={errors.postalCode?.message}
          inputProps={{
            maxLength: 7,
            pattern: "\\d{7}",
          }}
          onChange={handlePostalCodeFetch}
        />

        <TextField
          required
          label="住所(都道府県)"
          InputLabelProps={{ shrink: true }}
          {...register("prefecture")}
          error={"prefecture" in errors}
          helperText={errors.prefecture?.message}
        />

        <TextField
          required
          label="住所(市区町村)"
          InputLabelProps={{ shrink: true }}
          {...register("address1")}
          error={"address1" in errors}
          helperText={errors.address1?.message}
        />

        <TextField
          required
          label="住所(町名以下)"
          InputLabelProps={{ shrink: true }}
          {...register("address2")}
          error={"address2" in errors}
          helperText={errors.address2?.message}
        />

        <TextField
          label="アパート・ビル名、部屋番号等"
          InputLabelProps={{ shrink: true }}
          {...register("address3")}
          error={"address3" in errors}
          helperText={errors.address3?.message}
        />

        <TextField
          required
          label="電話番号"
          InputLabelProps={{ shrink: true }}
          {...register("phone")}
          error={"phone" in errors}
          helperText={errors.phone?.message}
        />
      </Stack>

      <Grid
        container
        pt={3}
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={3} sx={{ marginRight: 2 }}>
          <Button
            sx={cancelButtonStyle}
            color="secondary"
            variant="outlined"
            onClick={() => navigation(-1)}
          >
            キャンセルする
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            sx={updateButtonStyle}
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            更新する
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
};
export default UserEdit;
