import { useEffect, useState } from "react";
import { Grid, Button, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { getPharmacyList } from "../../lib/api/pharmacy";

import { PharmacyFormData } from "../schema/FacilitySchema";
import LayoutWithMenu from "../LayoutWithMenu";
import { ButtonCaption } from "../../utils/Constants";

const styles = {
  noteMobile: {
    pb: 1,
    fontSize: 12,
    display: { xs: "block", sm: "none" },
  },
  notePC: {
    pb: 1,
    fontSize: 12,
    display: { xs: "none", sm: "block" },
  },
  GridContainer: {
    borderBottom: 1,
    borderColor: "lightGrey",
    py: 1,
  },
  GridItem: {
    alignContent: "center",
  },
  LinkStyle: {
    cursor: "pointer",
  },
  DepartmentLabel: {
    fontSize: 12,
    marginTop: 2,
    marginLeft: 6,
  },
};

const AdminPharmacyList = () => {
  const [pharmacies, setPharmacies] = useState<Array<PharmacyFormData>>([]);

  const navigation = useNavigate();

  // 初期表示処理
  useEffect(() => {
    (async () => {
      await getPharmacyList()
        .then((response) => {
          setPharmacies(response.data);
        })
        .catch((error) => {
          console.error("薬局一覧の取得に失敗しました", error);
          // エラー処理
        });
    })();
  }, []);

  // 詳細画面遷移
  const moveToDetail = (data: PharmacyFormData) => {
    navigation(
      `/admin/facility/detail/?facility_id=${data.id}&facility_type=pharmacy`,
    );
  };

  return (
    <LayoutWithMenu pageTitle={"薬局一覧"}>
      <Typography sx={styles.noteMobile}>
        ※ 薬局名をタップすると詳細情報を表示します
      </Typography>
      <Typography sx={styles.notePC}>
        ※ 薬局名をクリックすると詳細情報を表示します
      </Typography>
      {pharmacies.map((data, index) => {
        return (
          <Grid container key={index} sx={styles.GridContainer}>
            <Grid container item xs={10} sx={styles.GridItem}>
              <Link sx={styles.LinkStyle} onClick={() => moveToDetail(data)}>
                {data.name}
              </Link>
              <span style={styles.DepartmentLabel}>{data.department}</span>
            </Grid>
            <Grid container item xs={2} sx={styles.GridItem}>
              <Button
                color="primary"
                variant="contained"
                size="medium"
                onClick={() => moveToDetail(data)}
              >
                {ButtonCaption.editShort}
              </Button>
            </Grid>
          </Grid>
        );
      })}
    </LayoutWithMenu>
  );
};
export default AdminPharmacyList;
