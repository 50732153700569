import yup from "../../utils/yup.locale";

// フォームの型
export class PasswordResetFormData {
  email: string = "";
  redirect_url?: string = "";
}

// バリデーションルール
export const PasswordResetSchema = yup.object({
  email: yup.string().email().required(),
  // redirect_url: yup
  //   .string(),
});
