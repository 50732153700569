import yup from "../../utils/yup.locale";
import { Sex } from "../../utils/Constants";

// フォームの型
export class UserFormData {
  id?: number = 0;
  email?: string = "";
  name: string = "";
  kana: string = "";
  sex: number = Sex.male;
  birth: string = "";
  postalCode: string = "";
  prefecture: string = "";
  address1: string = "";
  address2: string = "";
  address3?: string | null = null;
  phone?: string = "";
}

// バリデーションルール
export const UserSchema = yup.object({
  name: yup.string().required(),
  kana: yup.string().required(),
  sex: yup.number().required("性別を選択してください"),
  birth: yup.string().required(),
  postalCode: yup
    .string()
    .required()
    .matches(/^\d{7}$|^\d{3}-\d{4}$/, "半角数字7桁で入力してください"),
  prefecture: yup.string().required().max(10),
  address1: yup.string().max(50).required(),
  address2: yup.string().max(50).required(),
  address3: yup.string().max(50).nullable(),
  phone: yup
    .string()
    .max(11, "半角数字11桁以下で入力してください")
    .matches(/^[0-9]*$/, "半角数字11桁以下で入力してください")
    .required(),
});
export type UserInput = yup.InferType<typeof UserSchema>;
