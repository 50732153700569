import yup from "../../utils/yup.locale";

// フォームの型
export class FacilityStaffFormData {
  facility_id: string = "";
  name?: string = "";
  email: string = "";
  password: string = "";
}

// バリデーションルール
export const FacilityStaffSchema = yup.object({
  facility_id: yup.string().required(),
  name: yup.string(),
  email: yup.string().email().required(),
  password: yup
    .string()
    .min(8, "英数小文字8文字以上で入力してください")
    .max(128)
    .matches(/^(?=.*[a-z])(?=.*\d)[a-z\d]+$/, "半角英数字を両方含めてください")
    .required(),
});
