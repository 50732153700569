import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
  MenuItem,
  AlertColor,
} from "@mui/material";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FacilityStaffFormData,
  FacilityStaffSchema,
} from "../schema/FacilityStaffSchema";

import { Snackbar } from "../parts/Snackbar";
import { ButtonCaption } from "../../utils/Constants";

import LayoutWithMenu from "../LayoutWithMenu";

import { styles } from "../style/commonStyles";

import { useAuthFacilityStaffContext } from "../../contexts/AuthFacilityStaffContext";
import { getClinicList } from "../../lib/api/clinic";

const ClinicStaffNew = () => {
  const signUp = useAuthFacilityStaffContext().signUp;

  const navigation = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  // クリニックのSELECTの初期値に使う施設ID
  const facilityId = searchParams.get("facility_id") ?? "";

  const [created, setCreated] = useState(false);
  const [clinics, setClinics] = useState<{ id: number; name: string }[]>([
    { id: 0, name: "" },
  ]);

  // クリニックのSELECT選択肢(facility_id)をstateに保持
  const [selectedValue, setSelectedValue] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");

  useEffect(() => {
    (async () => {
      // clinics を取得
      const res = await getClinicList();
      setClinics(res.data);
    })();
  }, []);

  // clinicsデータが取得されたタイミングでクリニックの選択肢をセットする
  // 引数facility_idがあればそれを初期値に
  useEffect(() => {
    setSelectedValue(
      clinics.find((cl) => cl.id.toString() === facilityId)?.id.toString() ??
        "",
    );
  }, [clinics]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(FacilityStaffSchema),
  });

  // フォーム送信時の処理
  const onSubmit: SubmitHandler<FacilityStaffFormData> = async (data) => {
    const facility_id = data.facility_id;
    const name = data.name;
    const email = data.email;
    const password = data.password;
    const params: FacilityStaffFormData = {
      facility_id,
      name,
      email,
      password,
    };

    signUp(
      params,
      () => {
        setSnackbarProps("success", "施設スタッフを作成しました", true);
        setCreated(true);
      },
      (error) => {
        const errorMessage =
          error.response?.data?.errors?.fullMessages?.join("\n");
        setSnackbarProps(
          "warning",
          `作成出来ませんでした（${errorMessage}）`,
          true,
        );
      },
    );
  };

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (
    severity: AlertColor,
    message: string,
    isOpen: true,
  ) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  };

  return (
    <LayoutWithMenu pageTitle={"医療機関 スタッフ新規登録"}>
      {created ? (
        <>
          <Typography sx={{ m: 3 }} variant="subtitle2" align="center">
            登録完了しました。
          </Typography>
          <Link component={RouterLink} to="/admin" variant="body2">
            ダッシュボードへ
          </Link>
        </>
      ) : (
        <>
          <Stack spacing={3} sx={{ pt: 3 }}>
            <TextField
              select
              required
              label="クリニック"
              value={selectedValue}
              InputLabelProps={{ shrink: true }}
              {...register("facility_id")}
              error={"facility_id" in errors}
              helperText={errors.facility_id?.message}
              onChange={(e) => setSelectedValue(e.target.value)}
            >
              {clinics.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              required
              label="氏名"
              InputLabelProps={{ shrink: true }}
              {...register("name")}
              error={"name" in errors}
              helperText={errors.name?.message}
            />

            <TextField
              required
              label="メールアドレス"
              InputLabelProps={{ shrink: true }}
              {...register("email")}
              error={"email" in errors}
              helperText={errors.email?.message}
            />

            <TextField
              required
              label="パスワード"
              type="password"
              InputLabelProps={{ shrink: true }}
              {...register("password")}
              error={"password" in errors}
              helperText={errors.password?.message}
            />
          </Stack>

          <Grid container pt={3}>
            <Grid item xs={3}>
              <Button
                color="secondary"
                variant="outlined"
                sx={styles.backButton}
                onClick={() => navigation(-1)}
              >
                {ButtonCaption.back}
              </Button>
            </Grid>
            <Grid item xs={9}>
              <Button
                color="primary"
                variant="contained"
                sx={styles.button}
                onClick={handleSubmit(onSubmit)}
              >
                {ButtonCaption.register}
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
};
export default ClinicStaffNew;
