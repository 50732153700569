import { IconButton, Stack } from "@mui/material";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import SettingsIcon from "@mui/icons-material/Settings";
import { DeviceSelectionModal } from "components/organisms/DeviceSelectionModal";
import { MeetingManager } from "amazon-chime-sdk-component-library-react";

interface MeetingControlBoxProps {
  isMeetingConnected: boolean;
  isVideoEnabled: boolean;
  isDeviceModalOpen: boolean;
  muted: boolean;
  toggleVideo: () => void;
  toggleMute: () => void;
  handleDeviceModalOpen: (open: boolean) => void;
  meetingManager: MeetingManager;
}

export function MeetingVideoControlBox(
  props: MeetingControlBoxProps,
): React.ReactElement {
  const {
    isMeetingConnected,
    isVideoEnabled,
    isDeviceModalOpen,
    muted,
    toggleVideo,
    toggleMute,
    handleDeviceModalOpen,
    meetingManager,
  } = props;

  return (
    <>
      <DeviceSelectionModal
        open={isDeviceModalOpen}
        onClose={() => handleDeviceModalOpen(false)}
        meetingManager={meetingManager}
      />
      <Stack
        alignItems={"center"}
        bgcolor={"black"}
        borderRadius={"4px"}
        p={1}
        direction={"row"}
        justifyContent={"center"}
        gap={2}
      >
        <IconButton
          size="large"
          aria-label="自分の映像切り替え"
          onClick={toggleVideo}
          disabled={!isMeetingConnected}
          sx={{
            bgcolor: "white",
            "&:hover": {
              bgcolor: "#f5f5f5",
            },
          }}
        >
          {isVideoEnabled ? (
            <VideocamIcon
              sx={{
                color: "black",
              }}
            />
          ) : (
            <VideocamOffIcon
              sx={{
                color: "black",
              }}
            />
          )}
        </IconButton>
        <IconButton
          size="large"
          aria-label="自分の声のミュート切り替え"
          onClick={toggleMute}
          disabled={!isMeetingConnected}
          sx={{
            bgcolor: "white",
            "&:hover": {
              bgcolor: "#f5f5f5",
            },
          }}
        >
          {muted ? (
            <MicOffIcon
              sx={{
                color: "black",
              }}
            />
          ) : (
            <MicIcon
              sx={{
                color: "black",
              }}
            />
          )}
        </IconButton>
        <IconButton
          size="large"
          aria-label="音声・ビデオ設定"
          onClick={() => handleDeviceModalOpen(true)}
          sx={{
            bgcolor: "white",
            "&:hover": {
              bgcolor: "#f5f5f5",
            },
          }}
        >
          <SettingsIcon
            sx={{
              color: "black",
            }}
          />
        </IconButton>
      </Stack>
    </>
  );
}
