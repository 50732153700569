export const styles = {
  button: {
    width: "100%",
    height: 40,
  },
  backButton: {
    width: "80%",
    height: 40,
    mr: 3,
  },
  buttonSimple: {
    width: "100%",
    boxShadow: "none",
    fontSize: { xs: "0.75rem", sm: "0.875rem" },
  },
  buttonSingle: {
    width: "18em",
    boxShadow: "none",
    fontSize: { xs: "0.75rem", sm: "0.875rem" },
  },
  buttonBoldTextNoHover: {
    color: "#171C1F",
    fontWeight: "bold",
    "&:hover": { color: "#171C1F", backgroundColor: "transparent" },
  },
  buttonOutlined: {
    width: "100%",
    boxShadow: "none",
    color: "#171C1F",
    borderColor: "#171C1F",
    "&:hover": { borderColor: "#70787C", backgroundColor: "#f3f1f8" },
  },
  gridContainer: {
    borderBottom: 1,
    borderColor: "lightGrey",
    py: 2,
  },
  gridItemLabel: {
    fontWeight: "bold",
  },
  gridItemContent: {
    pl: 3,
    width: "80%",
    whiteSpace: "break-spaces",
  },
  TextField: {
    "& .MuiInputBase-input": {
      color: "#171C1F", // 入力文字の色
    },
    "& label": {
      backgroundColor: "#F5FAFD",
      padding: "4px 0",
      color: "#171C1F", // 通常時のラベルのテキスト色
      "&.Mui-focused": {
        color: "#171C1F", // フォーカス時のラベルのテキスト色
      },
      "&.Mui-error": {
        color: "#d32f2f", // エラー時のラベルのテキスト色
      },
    },
    "& .MuiOutlinedInput-root": {
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#02677d", // ホバー時のボーダー色(アウトライン)
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#171C1F", // 通常時のボーダー色(アウトライン)
      },
      "&.Mui-error": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#d32f2f", // エラー時のボーダー色(アウトライン)
        },
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#02677d", // エラー時のボーダー色(アウトライン)
        },
      },
    },
  },
  TypographyPageTitle: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "700",
    mt: 4,
    pb: 2,
  },
};
