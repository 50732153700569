import React, { useEffect, useState } from "react";

import LayoutWithMenu from "../LayoutWithMenu";
import UserImageListCommon from "../facility/UserImageListCommon";
import { ReservedUser } from "../../interfaces";
import { getReservedUserList } from "../../lib/api/user";
import { useAuthFacilityStaffContext } from "../../contexts/AuthFacilityStaffContext";

const MedicineBookList = () => {
  const [reservedUsers, setReservedUsers] = useState<ReservedUser[]>([]);
  const authFacilityStaff = useAuthFacilityStaffContext().authFacilityStaff;

  useEffect(() => {
    fetchReservedUserList();
  }, [authFacilityStaff]);

  // 初期表示
  const fetchReservedUserList = async () => {
    if (!authFacilityStaff) {
      return;
    }

    try {
      const response = await getReservedUserList(
        authFacilityStaff.facilityId.toString(),
      );
      setReservedUsers(response.data);
    } catch (error) {
      console.error("ユーザー一覧の取得に失敗しました", error);
    }
  };

  return (
    <LayoutWithMenu pageTitle={"お薬手帳"}>
      <UserImageListCommon
        reservedUsers={reservedUsers}
        imagePropName={"medicineNotebookImages"}
      />
    </LayoutWithMenu>
  );
};
export default MedicineBookList;
