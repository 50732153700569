import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthUserContext } from "../contexts/AuthUserContext";

const SignOut = () => {
  const navigate = useNavigate();
  const signOut = useAuthUserContext().signOut;
  useEffect(() => {
    signOut(() => {
      // console.log('ログアウト!')
      navigate("/login");
    });
  }, []);

  return <></>;
};
export default SignOut;
