import { useEffect, useState } from "react";
import { useAuthUserContext } from "../contexts/AuthUserContext";
import { UserReservation } from "../interfaces";
import { getReservationListByUser } from "../lib/api/reservation";
import { useNavigate } from "react-router-dom";
import LayoutWithMenu from "./LayoutWithMenu";
import { Box, Button, Card, CardActions, Grid, Tabs, Tab } from "@mui/material";
import { formattedDatetime } from "../utils/helper";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import React from "react";
import { icons } from "../icon";
import { CardContent, Typography } from "@mui/material";
import { ReservationStatus } from "../utils/Constants";

interface CardProps {
  title: string;
  buttonText: string;
  link: string;
  icon: React.ElementType;
}

const styles = {
  GridContainer: {
    borderBottom: 1,
    borderColor: "lightGrey",
    py: 1,
    fontSize: 16,
    textDecoration: "none",
    color: "#003642",
    backgroundColor: "#F5FAFD",
    paddingTop: 1,
    paddingBottom: 1,
  },
  GridItem: {
    alignContent: "center",
    backgroundColor: "#F5FAFD",
  },
  StrikeThrough: {
    position: "relative",
    "&::after": {
      width: "100%",
      height: "100%",
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      background:
        "linear-gradient(transparent 47%, #000000 47%, #000000 52%, transparent 52%)",
    },
  },
  CardTitle: {
    marginLeft: "16px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "28px",
    textAlign: "left",
    color: "#171C1F",
  },
  TabText: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    letterSpacing: "0.1px",
    textAlign: "center",
    color: "#003642",
  },
  GridText: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: "#003642",
  },
  BoxContainer: {
    border: "1px solid black",
    padding: "16px",
    borderRadius: "8px",
    marginTop: "16px",
  },
};

const Top = () => {
  const [reservations, setReservations] = useState<UserReservation[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const authUser = useAuthUserContext().authUser;
  const navigate = useNavigate();
  const today = new Date();

  useEffect(() => {
    fetchReservations();
  }, [authUser]);

  // 初期表示
  const fetchReservations = async () => {
    if (!authUser) {
      return;
    }

    try {
      const response = await getReservationListByUser(authUser.id.toString());
      setReservations(response.data);
    } catch (error) {
      console.error("予約一覧の取得に失敗しました", error);
    }
  };

  const cardData = [
    {
      title: "クリニック",
      buttonText: "クリニックを探す",
      link: "/medical_institution",
      icon: icons.HospitalIcon,
    },
    {
      title: "薬局",
      buttonText: "薬局を探す",
      link: "/pharmacy",
      icon: icons.MedicinesIcon,
    },
    {
      title: "お薬手帳",
      buttonText: "登録する",
      link: "/medicine_notebook",
      icon: icons.NotebookLinearIcon,
    },
    {
      title: "保険証",
      buttonText: "登録する",
      link: "/insurance_card",
      icon: icons.IdCardOutlineIcon,
    },
  ];

  const CustomCard = ({ title, buttonText, link, icon: Icon }: CardProps) => (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        padding: 2,
        border: "1px solid #87D1EA",
        borderRadius: "8px",
        backgroundColor: "#F5FAFD",
        boxShadow: "none",
        height: "60px",
        marginX: 2,
      }}
    >
      <Icon style={{ width: "20px", height: "20px", marginRight: "2px" }} />
      <CardContent sx={{ flex: 1, padding: "0" }}>
        <Typography variant="h6" sx={styles.CardTitle}>
          {title}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          onClick={() => navigate(link, { state: { fromLink: true } })}
          color="primary"
          variant="contained"
          size="large"
          sx={{
            minWidth: 120,
            width: 140, // ボタンの幅を固定
            height: 32,
            backgroundColor: "#87D1EA",
            boxShadow: "none",
            padding: "4px 16px",
            border: "1px solid #87D1EA",
            borderRadius: "3px",
            "&:hover": {
              backgroundColor: "#1aa3c9",
              boxShadow: "none",
            },
          }}
          style={{
            fontFamily: "Roboto",
            fontSize: "10px",
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "0.10000000149011612px",
            textAlign: "center",
            color: "#003642",
          }}
        >
          {buttonText}
        </Button>
      </CardActions>
    </Card>
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  const filteredReservations =
    tabIndex === 0
      ? reservations.filter((reservation) => {
          const reservationDate = new Date(reservation.reservationTime);
          return reservationDate.toDateString() === today.toDateString();
        })
      : reservations.filter((reservation) => {
          const reservationDate = new Date(reservation.reservationTime);
          return reservationDate.toDateString() !== today.toDateString();
        });

  return (
    <LayoutWithMenu>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        TabIndicatorProps={{ style: { display: "none" } }}
        sx={{
          ".MuiTab-root": {
            backgroundColor: "#F5FAFD",
            minWidth: 150,
            "&.Mui-selected": {
              // 選択されたタブのスタイル
              backgroundColor: "#87D1EA",
              color: "#000",
            },
          },
          marginTop: 5,
        }}
      >
        <Tab label="本日の予約" sx={styles.TabText} />
        <Tab label="その他の予約" sx={styles.TabText} />
      </Tabs>

      {filteredReservations.length === 0 ? <h3>{"予約はありません"}</h3> : null}

      {filteredReservations.map((reservation, index) =>
        reservation.isCanceled ? ( // キャンセルの予定
          <Grid container key={index} sx={styles.GridContainer}>
            <Grid container item xs={12} sx={styles.GridItem}>
              <Box display="flex" flexDirection="row" p={1}>
                {/* 取り消し線を適用したいテキスト部分を別のBoxで囲む */}
                <Box
                  sx={{
                    ...styles.StrikeThrough,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      ...styles.GridText,
                      width: "130px",
                      marginRight: 0.5,
                    }}
                  >
                    {reservation.reservationTime
                      ? formattedDatetime(reservation.reservationTime)
                          .split(" ")[0]
                          .replace(/\(.+?\)/, "") // 括弧と曜日を削除
                      : ""}
                  </Typography>
                  <Typography
                    sx={{
                      ...styles.GridText,
                      width: "50px",
                      textAlign: "center",
                      marginRight: 5,
                    }}
                  >
                    {reservation.reservationTime
                      ? formattedDatetime(reservation.reservationTime).split(
                          " ",
                        )[1]
                      : ""}
                  </Typography>
                  <Typography sx={styles.GridText}>
                    {reservation.facility.name}
                  </Typography>
                </Box>
                {/* 取り消し線を適用しないChip部分 */}
                <Chip
                  label="キャンセル"
                  variant="outlined"
                  sx={{
                    marginLeft: "24px",
                    borderRadius: "8px",
                    borderColor: "rgba(0, 0, 0, 0.23)",
                    borderWidth: "1px",
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    color: "#003642",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        ) : reservation.status === ReservationStatus.Completed ? ( // 完了済みの予定
          <Link
            to={`/reservation/${reservation.id}`}
            state={{ fromLink: true }}
            style={{ textDecoration: "none" }}
            key={index}
          >
            <Grid container key={index} sx={styles.GridContainer}>
              <Grid container item xs={12} sx={styles.GridItem}>
                <Box
                  display="flex"
                  flexDirection="row"
                  p={1}
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      ...styles.GridText,
                      width: "130px",
                      marginRight: 0.5,
                    }}
                  >
                    {reservation.reservationTime
                      ? formattedDatetime(reservation.reservationTime)
                          .split(" ")[0]
                          .replace(/\(.+?\)/, "") // 括弧と曜日を削除
                      : ""}
                  </Typography>
                  <Typography
                    sx={{
                      ...styles.GridText,
                      width: "50px",
                      textAlign: "center",
                      marginRight: 5,
                    }}
                  >
                    {reservation.reservationTime
                      ? formattedDatetime(reservation.reservationTime).split(
                          " ",
                        )[1]
                      : ""}
                  </Typography>
                  <Typography sx={styles.GridText}>
                    {reservation.facility.name}
                  </Typography>
                  <Chip
                    label="完了"
                    variant="outlined"
                    sx={{
                      marginLeft: "24px",
                      borderRadius: "8px",
                      border: "none",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                      color: "#003642",
                      backgroundColor: "#CEE6F0",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Link>
        ) : (
          // 通常の予定
          <Link
            to={`/reservation/${reservation.id}`}
            state={{ fromLink: true }}
            style={{ textDecoration: "none" }}
            key={index}
          >
            <Grid container key={index} sx={styles.GridContainer}>
              <Grid container item xs={12} sx={styles.GridItem}>
                <Box display="flex" flexDirection="row" p={1}>
                  <Typography
                    sx={{
                      ...styles.GridText,
                      width: "130px",
                      marginRight: 0.5,
                    }}
                  >
                    {reservation.reservationTime
                      ? formattedDatetime(reservation.reservationTime)
                          .split(" ")[0]
                          .replace(/\(.+?\)/, "") // 括弧と曜日を削除
                      : ""}
                  </Typography>
                  <Typography
                    sx={{
                      ...styles.GridText,
                      width: "50px",
                      textAlign: "center",
                      marginRight: 5,
                    }}
                  >
                    {reservation.reservationTime
                      ? formattedDatetime(reservation.reservationTime).split(
                          " ",
                        )[1]
                      : ""}
                  </Typography>
                  <Typography sx={styles.GridText}>
                    {reservation.facility.name}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Link>
        ),
      )}
      <p style={{ fontWeight: "bold" }}>
        ※スムーズな診療のため、予約時間前にビデオ通話に入室してお待ちください。
      </p>
      <div>
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          sx={{ width: "100%", marginTop: 4 }}
        >
          {cardData.map((card, index) => (
            <CustomCard
              key={index}
              title={card.title}
              buttonText={card.buttonText}
              link={card.link}
              icon={card.icon}
            />
          ))}
        </Box>
      </div>
    </LayoutWithMenu>
  );
};

export default Top;
