import React from "react";
import { useEffect, useState, useRef, createRef, RefObject } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Button,
  AlertColor,
  FormHelperText,
} from "@mui/material";

import {
  getPharmacyReservationWithPrescription,
  getPharmacyReservationRelation,
  updatePrescriptionStatus,
} from "../../lib/api/reservation";
import mime from "mime";

import LayoutWithMenu from "../LayoutWithMenu";

import {
  FacilityReservation,
  PharmacyReservationRelation,
  ReservationPrescription,
} from "../../interfaces";
import { styles as commonStyles } from "../style/commonStyles";
import ImageModal from "../../components/parts/ImageModal";
import CustomDialog from "../parts/CustomDialog";
import { Snackbar } from "../parts/Snackbar";
import { ButtonCaption } from "../../utils/Constants";
import { formattedDatetime } from "../../utils/helper";
import { PrescribeStatus } from "../../utils/Constants";
import { Margin } from "@mui/icons-material";
import { PDFThumbnail } from "components/atoms/PDFThumbnail";
import { useHistoryContext } from "contexts/HistoryContext";

const styles = {
  ...commonStyles,
  GridContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    fontSize: 14,
    textDecoration: "none",
    color: "#000000",
    backgroundColor: "#F5FAFD",
  },
  GridItem: {
    flex: "1 1 calc(20% - 10px)",
    boxSizing: "border-box",
    alignContent: "center",
  },
  reservationName: {
    fontSize: "1.5em",
    fontWeight: "bold",
  },
};

// 処方せん一覧用定義
type PrescriptionInfo = {
  facilityName: string;
  facilityNameShort: string;
  reservationTime: React.ReactNode;
  prescription: ReservationPrescription;
  isOnlyMedNoRx?: boolean;
};

// 型定義
type PrescriptionFile = {
  url: string;
};

type Prescription = {
  prescriptionFiles: PrescriptionFile[];
};

const PrescriptionList = () => {
  // 遷移パラメータ
  const navigation = useNavigate();
  const { reservation_id: pharmacyReservationId } = useParams();

  // 画面表示用項目
  const [reservation, setReservation] = useState<FacilityReservation>(
    {} as FacilityReservation,
  );
  const [reservationRelations, setReservationRelations] = useState<
    PharmacyReservationRelation[]
  >([]);
  const [prescriptions, setPrescriptions] = useState<PrescriptionInfo[]>([]);

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalImgSrc, setModalImgSrc] = useState<string>("");

  // 処方せん表示領域制御用
  const prescriptionRef = useRef<RefObject<HTMLDivElement>[]>([]);
  const [displayIndex, setDisplayIndex] = useState<number>(-1);

  // dialog用
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<string>("");
  const [isHiddenCancel, setIsHiddenCancel] = useState<boolean>(false);
  const [handleOnConfirm, setHandleOnConfirm] = useState<() => void>(() => {});

  const { previousPage } = useHistoryContext();
  const canGoBack = previousPage !== null;

  // MIMEタイプ判定関数
  const isPdfFile = (url: string) => {
    const mimeType = mime.getType(url.split("?")[0]); // クエリパラメータを除去してMIMEタイプを判定
    return mimeType === "application/pdf";
  };

  useEffect(() => {
    // パラメータチェック:必要なパラメータが空の場合、予約一覧から処理をやり直す
    if (!pharmacyReservationId) {
      setDialogContent(
        "処理が正しく行われませんでした。最初からやり直してください",
      );
      setIsHiddenCancel(true);
      setHandleOnConfirm(() => moveToReservationList);
      setIsDialogOpen(true);
      return;
    }
    // 初期表示データ設定
    setInitDisplayData();
  }, []);

  // 処方せん一覧表示部整形
  useEffect(() => {
    prescriptionDataShaped(reservation, reservationRelations);
  }, [reservation, reservationRelations]);

  // 予約一覧画面へ戻る
  const moveToReservationList = () => {
    navigation("/facility/reservation");
  };

  //　初期データ表示内容取得＆設定
  const setInitDisplayData = async () => {
    await getPharmacyReservation();
    await fetchPharmacyReservationRelation();
    setDisplayIndex(-1); // 開閉領域を戻す
  };

  const getPharmacyReservation = async () => {
    if (!pharmacyReservationId) {
      return;
    }

    // 薬局予約情報
    const reservationResult = await getPharmacyReservationWithPrescription(
      Number(pharmacyReservationId),
    );
    setReservation(reservationResult.data);
  };

  // 処方せん情報を含む関連予約情報の取得
  const fetchPharmacyReservationRelation = async () => {
    if (!pharmacyReservationId) {
      return;
    }

    const relationsResult = await getPharmacyReservationRelation(
      Number(pharmacyReservationId),
    );
    setReservationRelations(relationsResult.data);
    console.log("params.pharmacyReservationId", pharmacyReservationId);
  };

  // 処方せん情報部レコード整形
  const prescriptionDataShaped = (
    reservationData: FacilityReservation,
    listData: PharmacyReservationRelation[],
  ) => {
    let result = [] as PrescriptionInfo[];
    const clinicPrescriptions = listData.map((data) => {
      return {
        facilityName: data.reservationClinic.facility.name,
        facilityNameShort: data.reservationClinic.facility.nameShort,
        reservationTime: (
          <span>{`診療予約: ${formatDate(
            data.reservationClinic.reservationTime,
          )}`}</span>
        ),
        prescription: data.reservationClinic.prescription,
      } as PrescriptionInfo;
    });

    result = [...clinicPrescriptions];

    // 診療予約に紐づかない処方せん情報の表示
    if (reservationData?.onlyMedWithRx) {
      const patientUpPrescription = {
        facilityName: "",
        facilityNameShort: "（処方せん：あり）",
        reservationTime: "オンライン診療ではない診療",
        prescription: reservationData.prescriptions[0],
      } as PrescriptionInfo;
      result.push(patientUpPrescription);
    }

    if (reservationData?.onlyMedNoRx) {
      const noPrescription = {
        facilityName: "",
        facilityNameShort: "（処方せん：なし（医療機関にある））",
        reservationTime: "オンライン診療ではない診療",
        prescription: {},
        isOnlyMedNoRx: true,
      } as PrescriptionInfo;
      result.push(noPrescription);
    }

    createRefObject(result);
    setPrescriptions(result);
  };

  // 処方箋表示部への参照を作る
  const createRefObject = (prescriptionList: PrescriptionInfo[]) => {
    prescriptionList.map((_, i) => {
      prescriptionRef.current[i] = createRef<HTMLDivElement>();
    });
  };

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (
    severity: AlertColor,
    message: string,
    isOpen: true,
  ) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  };

  // Modal表示内容セット
  const setModalContent = (url: string) => {
    setModalImgSrc(url);
    setModalOpen(true);
  };

  // 処方せんステータスのUPDATE
  const prescriptionStatusChange = async (
    prescription_id: number,
    status: number,
  ) => {
    const param = {
      status: status,
    };
    try {
      const res = await updatePrescriptionStatus(prescription_id, param);
      // 画面最新化
      await setInitDisplayData();
      setSnackbarProps("success", "処方状況が更新されました", true);
    } catch (error) {
      setSnackbarProps("error", "処方状況の更新に失敗しました", true);
    }
  };

  // 処方せん表示領域開閉
  const openClosePrescriptionArea = (index: number) => {
    if (displayIndex === index) return setDisplayIndex(-1);
    setDisplayIndex(index);
  };

  // 予約日時のフォーマット
  const formatDate = (date: Date | undefined) => {
    if (date === undefined) return "";
    return formattedDatetime(date);
  };

  // 関連予約情報表示
  const relateReservationInfo = (prescription: PrescriptionInfo) => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
        <span>{prescription?.reservationTime}</span>
        <span style={{ marginLeft: "1em" }}>
          {prescription.facilityNameShort || prescription.facilityName}
        </span>
      </div>
    );
  };

  // メッセージ
  const messageForPrescription = (prescription: PrescriptionInfo) => {
    if (prescription.isOnlyMedNoRx) return "医療機関へ確認してください";
    if (
      !prescription.prescription ||
      prescription.prescription.prescriptionFiles.length === 0
    )
      return "処方せん未登録";
  };

  // 登録画像の枚数を計算する関数
  const calculateTotalImages = (prescriptions: Prescription[] = []): number => {
    return prescriptions.reduce((total: number, prescription: Prescription) => {
      return total + (prescription.prescriptionFiles?.length || 0);
    }, 0);
  };

  return (
    <LayoutWithMenu mainContentsMaxWidth="md">
      <Typography
        sx={{ ...styles.TypographyPageTitle, mt: 5, pb: 3, textAlign: "left" }}
      >
        処方せん登録
      </Typography>

      <Typography component="span" sx={{ ...styles.reservationName, pt: 20 }}>
        {`${reservation?.user?.name || ""}`}
      </Typography>
      <Typography component="span">
        <p>{`予約日時： ${formatDate(reservation?.reservationTime)}`}</p>
      </Typography>

      {/* 処方せん情報の表示 */}

      {prescriptions.map((data, index) => (
        <Grid
          container
          key={index}
          sx={styles.GridContainer}
          direction="row"
          alignItems="center"
          spacing={2}
          marginTop={2}
        >
          <Grid container item xs={6} sx={styles.GridItem}>
            <div
              style={{
                color: "#171C1F",
                fontSize: "16px",
                fontFamily: "Roboto",
              }}
            >
              {relateReservationInfo(data)}
            </div>
          </Grid>

          {/* 処方せん一覧 */}
          {messageForPrescription(data) && (
            <Grid container item xs={3} sx={styles.GridItem}>
              <FormHelperText error={true}>
                {messageForPrescription(data)}
              </FormHelperText>
            </Grid>
          )}

          {data.prescription?.prescriptionFiles &&
            !messageForPrescription(data) && (
              <>
                <Grid container item xs={3} sx={styles.GridItem}>
                  {data.prescription.status === PrescribeStatus.Prescribed ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        prescriptionStatusChange(
                          data.prescription.id,
                          PrescribeStatus.UnPrescribed,
                        );
                      }}
                      sx={{
                        color: "black",
                        backgroundColor: "#CEE6F0",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                    >
                      {"完了"}
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        prescriptionStatusChange(
                          data.prescription.id,
                          PrescribeStatus.Prescribed,
                        );
                      }}
                      sx={{
                        color: "#5e6568",
                        borderColor: "#70787C",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                    >
                      {"未完了"}
                    </Button>
                  )}
                </Grid>

                {/* 処方せん枚数 */}
                <Grid container item xs={2} sx={styles.GridItem}>
                  <Button
                    onClick={() => openClosePrescriptionArea(index)}
                    sx={{
                      boxShadow: "none",
                      width: "100px",
                      borderRadius: "10px",
                    }}
                  >
                    {`${data.prescription.prescriptionFiles.length}枚`}
                  </Button>
                </Grid>

                {/* 処方せん表示部 */}
                <div
                  ref={prescriptionRef.current[index]}
                  style={
                    displayIndex === index
                      ? {
                          height:
                            prescriptionRef.current[index].current
                              ?.scrollHeight || 0,
                          backgroundColor: "#FFFFFF",
                          width: "100%",
                        }
                      : {
                          height: "0px",
                          overflow: "hidden",
                          backgroundColor: "#FFFFFF",
                          width: "100%",
                        }
                  }
                >
                  <Grid container spacing={2}>
                    {data.prescription.prescriptionFiles.map((file, index) => (
                      <Grid item xs={3} md={6} sx={styles.GridItem} key={index}>
                        <Box
                          onClick={() => {
                            if (isPdfFile(file.url)) {
                              window.open(file.url, "_blank"); // PDFは新しいウィンドウで開く
                            } else {
                              setModalImgSrc(file.url); // 画像はモーダルに表示
                              setModalOpen(true);
                            }
                          }}
                          sx={{
                            maxWidth: "150px",
                            maxHeight: "150px",
                            border: "1px solid #ddd",
                            overflow: "hidden",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#f5f5f5",
                          }}
                        >
                          {isPdfFile(file.url) ? (
                            <Box
                              sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PDFThumbnail filePath={file.url} />
                            </Box>
                          ) : (
                            <img
                              src={file.url}
                              alt={`${index + 1}番目の画像`}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          )}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </>
            )}
        </Grid>
      ))}

      {/* 戻るボタン */}
      {canGoBack && (
        <Grid container pt={3} justifyContent="center">
          <Grid item xs={4}>
            <Button
              sx={{
                color: "black",
                borderColor: "black",
                "&:hover": {
                  backgroundColor: "transparent",
                  borderColor: "black",
                },
                width: "100%",
              }}
              color="secondary"
              variant="outlined"
              onClick={() => navigation(-1)}
            >
              {ButtonCaption.back}
            </Button>
          </Grid>
        </Grid>
      )}

      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
      <ImageModal
        open={modalOpen}
        setOpen={setModalOpen}
        imgSrc={modalImgSrc}
      />
      <CustomDialog
        isOpen={isDialogOpen}
        content={dialogContent}
        isHiddenCancel={isHiddenCancel}
        onConfirm={handleOnConfirm}
        onCancel={() => setIsDialogOpen(false)}
      />
    </LayoutWithMenu>
  );
};
export default PrescriptionList;
