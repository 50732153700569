import { useState } from "react";
import {
  Button,
  Stack,
  Grid,
  TextField,
  Typography,
  AlertColor,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PasswordResetFormData,
  PasswordResetSchema,
} from "../schema/PasswordResetSchema";

import { Snackbar } from "../parts/Snackbar";
import { ButtonCaption } from "../../utils/Constants";

import { styles } from "../style/commonStyles";
import LayoutWithMenu from "../LayoutWithMenu";

import { useNavigate } from "react-router-dom";
import { useAuthFacilityStaffContext } from "../../contexts/AuthFacilityStaffContext";

const FacilityStaffPasswordReset = () => {
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const passwordResetSuccess = searchParams.get("password_reset_success") || "";

  const passwordReset = useAuthFacilityStaffContext().passwordReset;

  const [sentMail, setSentMail] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");

  const onSubmit: SubmitHandler<PasswordResetFormData> = (data) => {
    const email = data.email;
    const params: PasswordResetFormData = {
      email,
      redirect_url: `${process.env.REACT_APP_ROOT_URL}/facility/password_update`,
    };

    passwordReset(
      params,
      () => {
        console.log("パスワードリセット!");
        setSentMail(true);
      },
      () => {
        setSnackbarProps(
          "warning",
          "メールアドレスを確認出来ませんでした",
          true,
        );
      },
    );
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordResetFormData>({
    resolver: yupResolver(PasswordResetSchema),
  });

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (
    severity: AlertColor,
    message: string,
    isOpen: true,
  ) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  };

  return (
    <LayoutWithMenu hideNavLinks={true}>
      {sentMail ? (
        <>
          <Typography sx={{ m: 3 }} variant="subtitle2" align="center">
            パスワードの変更依頼を受け付けました。数分以内にメールを送信いたしますので、内容をご確認ください。
          </Typography>
        </>
      ) : (
        <>
          {passwordResetSuccess === "false" && (
            <Typography sx={{ m: 3 }} variant="subtitle2" align="center">
              パスワード変更完了までの有効期限が切れています。
              <br />
              お手数ですが、パスワードの再発行手続きをお願いします。
            </Typography>
          )}

          <Stack spacing={5} sx={{ mt: 10 }}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              <Grid item xs={12}>
                <img
                  src="/images/login/login_logo@2x.png"
                  alt="Bizcare"
                  style={{ width: "179px", height: "72px" }}
                />
              </Grid>
            </Grid>
            <Typography sx={styles.TypographyPageTitle} align="center">
              パスワードの再発行
            </Typography>
            <Typography sx={{ m: 3 }} variant="subtitle2" align="center">
              登録しているメールアドレスに仮パスワードを送信します。
            </Typography>
          </Stack>
          <Stack spacing={7}>
            <Stack spacing={4} sx={{ pt: 3 }}>
              <TextField
                required
                label="メールアドレス"
                InputLabelProps={{ shrink: true }}
                {...register("email")}
                error={"email" in errors}
                helperText={errors.email?.message}
                placeholder="メールアドレスを入力してください"
                sx={styles.TextField}
              />
            </Stack>

            <Stack spacing={2}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="row"
              >
                <Grid item xs={6}>
                  <Button
                    sx={{
                      ...styles.buttonSingle,
                      color: "#70787C",
                      borderColor: "#70787C",
                      "&:hover": {
                        backgroundColor: (theme) => theme.palette.action.hover,
                        boxShadow: (theme) => theme.shadows[4],
                        borderColor: "#5a6169",
                        color: "#5a6169",
                      },
                    }}
                    variant="outlined"
                    onClick={() => navigate(-1)}
                  >
                    {ButtonCaption.cancel}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    sx={styles.buttonSingle}
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {ButtonCaption.send}
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </>
      )}
      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
};
export default FacilityStaffPasswordReset;
