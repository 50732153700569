import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Select,
  Typography,
  ListItemText,
} from "@mui/material";
import {
  MeetingManager,
  useAudioInputs,
  useAudioOutputs,
  useVideoInputs,
} from "amazon-chime-sdk-component-library-react";
import { SelectChangeEvent } from "@mui/material";

interface DeviceSelectionModalProps {
  open: boolean;
  onClose: () => void;
  meetingManager: MeetingManager;
}

export function DeviceSelectionModal(props: DeviceSelectionModalProps) {
  const meetingManager = props.meetingManager;

  // デバイス一覧を取得
  const {
    devices: audioInputDevices,
    selectedDevice: selectedAudioInputDevice,
  } = useAudioInputs();
  const {
    devices: audioOutputDevices,
    selectedDevice: selectedAudioOutputDevice,
  } = useAudioOutputs();
  const {
    devices: videoInputDevices,
    selectedDevice: selectedVideoInputDevice,
  } = useVideoInputs();

  // 現在の選択デバイスを保持
  const [selectedAudioInputDeviceId, setSelectedAudioInput] = useState<string>(
    selectedAudioInputDevice?.toString() || "",
  );
  const [selectedAudioOutputDeviceId, setSelectedAudioOutput] =
    useState<string>(selectedAudioOutputDevice || "");
  const [selectedVideoInputDeviceId, setSelectedVideoInput] = useState<string>(
    selectedVideoInputDevice?.toString() || "",
  );

  // モバイルデバイスかどうかを判定
  const isMobileDevice =
    /android|iphone|ipad|ipod|blackberry|windows phone|mobile/i.test(
      navigator.userAgent,
    );

  // モバイルデバイス用のフィルタリング
  const filteredVideoInputDevices = isMobileDevice
    ? videoInputDevices.filter(
        (device) => /front|back|前面カメラ|背面カメラ|rear/i.test(device.label), // 前面・背面のキーワードに基づいてフィルタリング
      )
    : videoInputDevices;

  const handleAudioInputChange = async (event: SelectChangeEvent<string>) => {
    const deviceId = event.target.value as string;
    setSelectedAudioInput(deviceId);
    await meetingManager.startAudioInputDevice(deviceId);
  };

  const handleAudioOutputChange = async (event: SelectChangeEvent<string>) => {
    const deviceId = event.target.value as string;
    setSelectedAudioOutput(deviceId);
    await meetingManager.startAudioOutputDevice(deviceId);
  };

  const handleVideoInputChange = async (event: SelectChangeEvent<string>) => {
    const deviceId = event.target.value as string;
    setSelectedVideoInput(deviceId);
    await meetingManager.startVideoInputDevice(deviceId);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>デバイス設定</DialogTitle>
      <DialogContent>
        <Typography>入力オーディオデバイス</Typography>
        <Select
          fullWidth
          value={selectedAudioInputDeviceId}
          placeholder="選択してください"
          onChange={handleAudioInputChange}
        >
          {audioInputDevices.map((device) => (
            <MenuItem key={device.deviceId} value={device.deviceId}>
              <ListItemText primary={device.label || "マイク"} />
            </MenuItem>
          ))}
        </Select>

        {/* モバイルのときは表示されないため */}
        {!isMobileDevice && (
          <>
            <Typography sx={{ mt: 2 }}>出力オーディオデバイス</Typography>
            <Select
              fullWidth
              value={selectedAudioOutputDeviceId}
              placeholder="選択してください"
              onChange={handleAudioOutputChange}
            >
              {audioOutputDevices.map((device) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  <ListItemText primary={device.label || "スピーカー"} />
                </MenuItem>
              ))}
            </Select>
          </>
        )}

        <Typography sx={{ mt: 2 }}>入力ビデオデバイス</Typography>
        <Select
          fullWidth
          value={selectedVideoInputDeviceId}
          placeholder="選択してください"
          onChange={handleVideoInputChange}
        >
          {filteredVideoInputDevices.map((device) => (
            <MenuItem key={device.deviceId} value={device.deviceId}>
              <ListItemText primary={device.label || "カメラ"} />
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
}
