import React from "react";
import LayoutWithMenu from "../LayoutWithMenu";
import FacilityEditCommon from "../facility/FacilityEditCommon";
import { FacilityAdminSchema } from "../schema/FacilitySchema";
import { capitalize } from "../../utils/helper";
import { useAuthAdminUserContext } from "../../contexts/AuthAdminUserContext";

const FacilityEditAdmin: React.FC = () => {
  const authUser = useAuthAdminUserContext().authAdminUser;
  const searchParams = new URLSearchParams(window.location.search);
  const facilityId = searchParams.get("facility_id") ?? "";
  const facilityType = capitalize(searchParams.get("facility_type") ?? "");

  return (
    <LayoutWithMenu pageTitle="基本情報変更">
      <FacilityEditCommon
        facilityId={facilityId}
        facilityType={facilityType}
        schema={FacilityAdminSchema}
        adminAuth={authUser}
      />
    </LayoutWithMenu>
  );
};
export default FacilityEditAdmin;
