import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";

export type IndicatorProps = {
  open: boolean;
};

// ローディング時のぐるぐる
export default function Indicator(props: IndicatorProps) {
  return (
    <Dialog open={props.open} maxWidth={false}>
      <DialogContent sx={{ overflow: "hidden" }}>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
}
