import { AuthAdminUserProvider } from "contexts/AuthAdminUserContext";
import { AuthFacilityStaffProvider } from "contexts/AuthFacilityStaffContext";
import { AuthUserProvider } from "contexts/AuthUserContext";
import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/style/theme";

import { MeetingProvider } from "amazon-chime-sdk-component-library-react";
import MedicineNotebook from "components/MedicineNotebook";
import ReservedUserHicard from "components/facility/ReservedUserHicard";
import ReservedUserMedicineNotebook from "components/facility/ReservedUserMedicineNotebook";
import Indicator from "components/parts/Indicator";
import Billing from "./components/Billing";
import FacilityCommerce from "./components/FacilityCommerce";
import InsuranceCard from "./components/InsuranceCard";
import { Login } from "./components/pages/Login";
import MedicalInstitutionDetail from "./components/MedicalInstitutionDetail";
import MedicalInstitutionList from "./components/MedicalInstitutionList";
import NotFound from "./components/NotFound";
import PasswordReset from "./components/PasswordReset";
import PasswordUpdate from "./components/PasswordUpdate";
import PharmacyDetail from "./components/PharmacyDetail";
import PharmacyList from "./components/PharmacyList";
import PharmacyReservation from "./components/PharmacyReservation";
import PrescriptionEdit from "./components/PrescriptionEdit";
import PrescriptionRegister from "./components/PrescriptionRegister";
import ReceiptDetail from "./components/ReceiptDetail";
import ReceiptDetailList from "./components/ReceiptDetailList";
import ReservationDetail from "./components/ReservationDetail";
import ReservationList from "./components/ReservationList";
import SignOut from "./components/SignOut";
import SignUp from "./components/SignUp";
import Top from "./components/Top";
import UserDetail from "./components/UserDetail";
import UserEdit from "./components/UserEdit";
import UserEmailEdit from "./components/UserEmailEdit";
import UserPasswordEdit from "./components/UserPasswordEdit";
import AdminMedicalInstitutionList from "./components/admin/AdminMedicalInstitutionList";
import AdminPharmacyList from "./components/admin/AdminPharmacyList";
import AdminSignOut from "./components/admin/AdminSignOut";
import AdminTest from "./components/admin/AdminTest";
import AdminUserLogin from "./components/admin/AdminUserLogIn";
import AdminUserPasswordReset from "./components/admin/AdminUserPasswordReset";
import AdminUserPasswordUpdate from "./components/admin/AdminUserPasswordUpdate";
import ClinicStaffEdit from "./components/admin/ClinicStaffEdit";
import ClinicStaffNew from "./components/admin/ClinicStaffNew";
import FacilityDetailAdmin from "./components/admin/FacilityDetailAdmin";
import FacilityEditAdmin from "./components/admin/FacilityEditAdmin";
import FacilityNewAdmin from "./components/admin/FacilityNewAdmin";
import PharmacyStaffEdit from "./components/admin/PharmacyStaffEdit";
import PharmacyStaffNew from "./components/admin/PharmacyStaffNew";
import UserDetailAdmin from "./components/admin/UserDetailAdmin";
import UserListAdmin from "./components/admin/UserListAdmin";
import AuthenticatedAdminUser from "./components/auth/AuthenticatedAdminUser";
import AuthenticatedFacilityStaff from "./components/auth/AuthenticatedFacilityStaff";
import AuthenticatedUser from "./components/auth/AuthenticatedUser";
import ClinicReservationDetail from "./components/facility/ClinicReservationDetail";
import FacilityDetail from "./components/facility/FacilityDetail";
import FacilityEdit from "./components/facility/FacilityEdit";
import FacilityReservationList from "./components/facility/FacilityReservation";
import FacilitySignOut from "./components/facility/FacilitySignOut";
import FacilityStaffLogin from "./components/facility/FacilityStaffLogIn";
import FacilityStaffPasswordReset from "./components/facility/FacilityStaffPasswordReset";
import FacilityStaffPasswordUpdate from "./components/facility/FacilityStaffPasswordUpdate";
import HicardList from "./components/facility/HicardList";
import MedicineBookList from "./components/pages/MedicineBookList";
import PharmacyReservationDetail from "./components/facility/PharmacyReservationDetail";
import PrescriptionList from "./components/facility/PrescriptionList";
import PrescriptionSending from "./components/facility/PrescriptionSending";
import ReceiptDetailSending from "./components/facility/ReceiptDetailSending";
import IndicatorData from "./lib/IndicatorData";
import { HistoryProvider } from "contexts/HistoryContext";

const App: React.FC = () => {
  const [indicator, setIndicator] = useState({ isOpen: false });
  IndicatorData.setIndicator = setIndicator;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MeetingProvider>
        <HistoryProvider>
          <AuthAdminUserProvider>
            <AuthFacilityStaffProvider>
              <AuthUserProvider>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/password_reset" element={<PasswordReset />} />
                  <Route path="/password_update" element={<PasswordUpdate />} />
                  <Route
                    path="/facility/login"
                    element={<FacilityStaffLogin />}
                  />
                  <Route
                    path="/facility/password_reset"
                    element={<FacilityStaffPasswordReset />}
                  />
                  <Route
                    path="/facility/password_update"
                    element={<FacilityStaffPasswordUpdate />}
                  />
                  <Route path="/admin/login" element={<AdminUserLogin />} />
                  <Route
                    path="/admin/password_reset"
                    element={<AdminUserPasswordReset />}
                  />
                  <Route
                    path="/admin/password_update"
                    element={<AdminUserPasswordUpdate />}
                  />
                  <Route path="*" element={<NotFound />} />
                  <Route
                    path="/facility"
                    element={<AuthenticatedFacilityStaff />}
                  >
                    <Route index element={<Navigate to="reservation" />} />
                    <Route
                      path="reservation"
                      element={<FacilityReservationList />}
                    />
                    <Route
                      path="reservation/clinic/:reservation_id"
                      element={<ClinicReservationDetail />}
                    />
                    <Route
                      path="reservation/clinic/:reservation_id/medicine_notebook"
                      element={<ReservedUserMedicineNotebook />}
                    />
                    <Route
                      path="reservation/clinic/:reservation_id/hicard"
                      element={<ReservedUserHicard />}
                    />
                    <Route
                      path="reservation/pharmacy/:reservation_id"
                      element={<PharmacyReservationDetail />}
                    />
                    <Route
                      path="reservation/pharmacy/:reservation_id/medicine_notebook"
                      element={<ReservedUserMedicineNotebook />}
                    />
                    <Route
                      path="reservation/pharmacy/:reservation_id/hicard"
                      element={<ReservedUserHicard />}
                    />
                    <Route
                      path="reservation/receipt_detail/:reservation_id/send"
                      element={<ReceiptDetailSending />}
                    />
                    <Route
                      path="reservation/prescription/:reservation_id/send"
                      element={<PrescriptionSending />}
                    />
                    <Route
                      path="reservation/prescription/:reservation_id/list"
                      element={<PrescriptionList />}
                    />
                    <Route path="edit" element={<FacilityEdit />} />
                    <Route path="detail" element={<FacilityDetail />} />
                    <Route path="signout" element={<FacilitySignOut />} />
                    <Route path="hicard" element={<HicardList />} />
                    <Route path="medicinebook" element={<MedicineBookList />} />
                    <Route
                      path="billing"
                      element={
                        <Billing facilityType="pharmacy" reservationId={1} />
                      }
                    />
                  </Route>
                  <Route path="/" element={<AuthenticatedUser />}>
                    <Route path="/top" element={<Top />} />
                    <Route path="/signout" element={<SignOut />} />
                    <Route index element={<Navigate to="top" />} />
                    <Route path="/user/edit" element={<UserEdit />} />
                    <Route path="/user" element={<UserDetail />} />
                    <Route
                      path="/user/email/edit"
                      element={<UserEmailEdit />}
                    />
                    <Route
                      path="/user/password/edit"
                      element={<UserPasswordEdit />}
                    />
                    <Route
                      path="/medical_institution"
                      element={<MedicalInstitutionList />}
                    />
                    <Route
                      path="/medical_institution/detail/:id"
                      element={<MedicalInstitutionDetail />}
                    />
                    <Route
                      path="/medical_institution/:facility_id/commerce"
                      element={<FacilityCommerce />}
                    />
                    <Route path="/pharmacy" element={<PharmacyList />} />
                    <Route
                      path="/pharmacy/detail/:id"
                      element={<PharmacyDetail />}
                    />
                    <Route
                      path="/pharmacy/:facility_id/commerce"
                      element={<FacilityCommerce />}
                    />
                    <Route
                      path="/pharmacy/reservation"
                      element={<PharmacyReservation />}
                    />
                    <Route path="/insurance_card" element={<InsuranceCard />} />
                    <Route
                      path="/medicine_notebook"
                      element={<MedicineNotebook />}
                    />
                    <Route path="/reservation" element={<ReservationList />} />
                    <Route
                      path="/reservation/:reservation_id"
                      element={<ReservationDetail />}
                    />
                    <Route
                      path="/reservation/:reservation_id/prescription"
                      element={<PrescriptionEdit />}
                    />
                    <Route
                      path="/receipt_detail"
                      element={<ReceiptDetailList />}
                    />
                    <Route
                      path="/receipt_detail/:reservation_id"
                      element={<ReceiptDetail />}
                    />
                    <Route
                      path="/prescription/register"
                      element={<PrescriptionRegister />}
                    />
                  </Route>
                  <Route path="/admin" element={<AuthenticatedAdminUser />}>
                    <Route index element={<Navigate to="user_list" />} />
                    <Route path="admin_test" element={<AdminTest />} />
                    <Route
                      path="clinic/list"
                      element={<AdminMedicalInstitutionList />}
                    />
                    <Route
                      path="pharmacy/list"
                      element={<AdminPharmacyList />}
                    />
                    <Route
                      path="facility/detail"
                      element={<FacilityDetailAdmin />}
                    />
                    <Route
                      path="facility/edit"
                      element={<FacilityEditAdmin />}
                    />
                    <Route path="facility/new" element={<FacilityNewAdmin />} />
                    <Route
                      path="clinic_staff/new"
                      element={<ClinicStaffNew />}
                    />
                    <Route
                      path="clinic_staff/edit"
                      element={<ClinicStaffEdit />}
                    />
                    <Route
                      path="pharmacy_staff/new"
                      element={<PharmacyStaffNew />}
                    />
                    <Route
                      path="pharmacy_staff/edit"
                      element={<PharmacyStaffEdit />}
                    />
                    <Route path="user" element={<UserDetailAdmin />} />
                    <Route path="user_list" element={<UserListAdmin />} />
                    <Route path="signout" element={<AdminSignOut />} />
                  </Route>
                </Routes>
                <div>
                  <Indicator open={indicator.isOpen} />
                </div>
              </AuthUserProvider>
            </AuthFacilityStaffProvider>
          </AuthAdminUserProvider>
        </HistoryProvider>
      </MeetingProvider>
    </ThemeProvider>
  );
};

export default App;
