import applyCaseMiddleware from "axios-case-converter";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import IndicatorData from "lib/IndicatorData";

// applyCaseMiddleware:
// axiosで受け取ったレスポンスの値をスネークケース→キャメルケースに変換
// または送信するリクエストの値をキャメルケース→スネークケースに変換してくれるライブラリ

// ヘッダーに関してはケバブケースのままで良いので適用を無視するオプションを追加
const options = {
  ignoreHeaders: true,
};

const apiBaseUrl =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3101/api/v1";
class client {
  static createInstance() {
    const instance = applyCaseMiddleware(
      axios.create({
        baseURL: apiBaseUrl,
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 30000,
      }),
      options,
    );
    instance.interceptors.response.use(
      (config) => this.requestSuccess(config),
      (config) => this.requestFailure(config),
    );
    return instance;
  }

  static requestFailure(config: AxiosResponse<any, any>) {
    //request失敗した時の処理
    // console.log('// REQUEST FAILURE', config)
    return Promise.reject(config);
  }
  static requestSuccess(config: AxiosResponse<any, any>) {
    //request成功した時の処理
    // console.log('// REQUEST SUCCESS', config)
    return config;
  }

  static get<T = any>(
    url: string,
    options: AxiosRequestConfig = {},
  ): Promise<T> {
    this.openIndicator();
    const instance = this.createInstance();
    return new Promise<T>((resolve, reject) => {
      instance
        .get(url, options)
        .then((response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          // console.log({error})
          return resolve(error.response.data);
          // console.log('after error: ', {error})
        })
        .finally(() => {
          this.closeIndicator();
        });
    });
  }

  static post<T = any>(
    url: string,
    param: any,
    options: AxiosRequestConfig = {},
  ): Promise<T> {
    this.openIndicator();
    const instance = this.createInstance();
    return new Promise<T>((resolve, reject) => {
      instance
        .post(url, param, options)
        .then((response) => {
          // console.log("axios post", {response})
          return resolve(response.data);
        })
        .catch((error) => {
          // console.log({error})
          reject(error);
        })
        .finally(() => {
          this.closeIndicator();
        });
    });
  }

  static postRetrieveDataAndHeaders<T>(
    url: string,
    param: any,
    options: AxiosRequestConfig = {},
  ): Promise<T> {
    this.openIndicator();
    const instance = this.createInstance();
    return new Promise<T>((resolve, reject) => {
      instance
        .post(url, param, options)
        .then((response) => {
          const ret = { data: response.data, headers: response.headers };
          return resolve(<any>ret);
        })
        .catch((error) => {
          // console.log({error})
          reject(error);
        })
        .finally(() => {
          this.closeIndicator();
        });
    });
  }

  static put<T>(
    url: string,
    param: any,
    options: AxiosRequestConfig = {},
  ): Promise<T> {
    this.openIndicator();
    const instance = this.createInstance();
    return new Promise<T>((resolve, reject) => {
      instance
        .put(url, param, options)
        .then((response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          this.closeIndicator();
        });
    });
  }

  static delete<T>(url: string, options: AxiosRequestConfig = {}): Promise<T> {
    this.openIndicator();
    const instance = this.createInstance();
    return new Promise<T>((resolve, reject) => {
      instance
        .delete(url, options)
        .then((response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          this.closeIndicator();
        });
    });
  }

  // インジケーターを開く
  private static openIndicator(): void {
    IndicatorData.openIndicator();
  }

  // インジケーターを閉じる
  private static closeIndicator(): void {
    IndicatorData.closeIndicator();
  }
}

export default client;
