interface IIndicatorData {
  isOpen: boolean;
}

class IndicatorData {
  public setIndicator: any;
  public callCount: number = 0;

  constructor(indicator: any, callCount: number) {
    this.setIndicator = indicator;
    this.callCount = callCount;
  }

  public openIndicator = () => {
    if (this.callCount === 0)
      this.setIndicator({ isOpen: true }) as IIndicatorData;
    this.callCount++;
  };

  public closeIndicator = () => {
    this.callCount--;
    if (this.callCount === 0)
      this.setIndicator({ isOpen: false }) as IIndicatorData;
  };
}
export default new IndicatorData(null, 0);
