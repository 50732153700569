import * as React from "react";
import { Snackbar as MuiSnackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  },
);

type SnackBarProps = {
  message: string;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  severity?: AlertColor;
};

export const Snackbar = (props: SnackBarProps) => {
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    props.setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <MuiSnackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={props.isOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        message={props.message}
        action={action}
      >
        <Alert
          onClose={handleClose}
          severity={props.severity || "success"}
          sx={{ width: "100%" }}
        >
          {props.message}
        </Alert>
      </MuiSnackbar>
    </div>
  );
};
