import React, { useEffect } from "react";

import {
  Button,
  Stack,
  Typography,
  Grid,
  TextField,
  Divider,
} from "@mui/material";

import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BillingData, BillingSchema } from "./schema/BillingSchema";
import LayoutWithMenu from "./LayoutWithMenu";
import { ButtonCaption } from "../utils/Constants";

import { useNavigate, Link as RouterLink } from "react-router-dom";

import client from "../lib/api/client";

const Billing = ({
  facilityType,
  reservationId,
}: {
  facilityType: string;
  reservationId: number;
}) => {
  const HANDLING_FEE = 1000;

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<BillingData>({
    resolver: yupResolver(BillingSchema),
  });

  const copaymentAmount = watch("copaymentAmount", 0);
  const shippingFee = watch("shippingFee", 0);

  const totalFee =
    copaymentAmount && shippingFee
      ? Number(copaymentAmount) + Number(shippingFee) + HANDLING_FEE
      : 0;

  useEffect(() => {
    setValue("handlingFee", HANDLING_FEE);
    setValue("totalFee", totalFee);
  }, [totalFee]);

  const onSubmit: SubmitHandler<BillingData> = async (data) => {
    try {
      await client.post("payments/create_payment_link", {
        ...data,
        facilityType,
        reservationId,
      });
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  return (
    <LayoutWithMenu pageTitle={"請求確定"} hideNavLinks={true}>
      <Stack spacing={3} sx={{ pt: 3 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={4}>
            <Typography>
              {facilityType === "clinic" ? "医療費" : "患者負担金"}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                {...register("copaymentAmount")}
                error={!!errors.copaymentAmount}
                helperText={errors.copaymentAmount?.message}
              />
              <Typography>円</Typography>
            </Stack>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={4}>
            <Typography>送料</Typography>
          </Grid>

          <Grid item xs={8}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                {...register("shippingFee")}
                error={!!errors.shippingFee}
                helperText={errors.shippingFee?.message}
              />
              <Typography>円</Typography>
            </Stack>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={4}>
            <Typography>手数料</Typography>
          </Grid>

          <Grid item xs={8}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                value={HANDLING_FEE}
                InputProps={{
                  readOnly: true,
                }}
              />
              <Typography>円</Typography>
            </Stack>
          </Grid>
        </Grid>

        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>合計</Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <TextField
              variant="outlined"
              value={totalFee}
              size="small"
              InputProps={{
                readOnly: true,
              }}
            />
            <Typography>円</Typography>
          </Stack>
        </Stack>

        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={handleSubmit(onSubmit)}
        >
          {ButtonCaption.billing}
        </Button>

        <Button
          color="primary"
          variant="outlined"
          size="large"
          onClick={() => navigate(-1)}
        >
          {ButtonCaption.cancel}
        </Button>
      </Stack>
    </LayoutWithMenu>
  );
};

export default Billing;
