import { useState } from "react";
import { useAuthUserContext } from "../contexts/AuthUserContext";
import { getUserDetail } from "../lib/api/user";
import { Messages } from "../utils/Constants";

export const useUserInfoRegistered = () => {
  const authUser = useAuthUserContext().authUser;
  const [userErrorMessage, setUserErrorMessage] = useState<string>("");
  const [userNotificationMessage, setUserNotificationMessage] =
    useState<string>("");

  // ユーザー情報が登録されているか
  const isUserInfoRegistered = async () => {
    let messages = [];
    const result = await getUserDetail(authUser?.id.toString() || "");
    // 名前が登録されていない
    if (!result.data.name) messages.push(Messages.userUnregisteredShort);

    // 保険証が１枚も登録されていない
    if (
      !result.data.hicardImage1.url &&
      !result.data.hicardImage2.url &&
      !result.data.hicardImage3.url &&
      !result.data.hicardImage4.url &&
      !result.data.hicardImage5.url &&
      !result.data.hicardImage6.url
    ) {
      messages.push(Messages.hicardUnregistered);
    }

    if (messages.length > 0)
      setUserErrorMessage(
        `${messages.join("\n")}\n登録が完了するまで、予約はできません。`,
      );

    // お薬手帳が１枚も登録されていない
    if (
      !result.data.medicineNotebook1.url &&
      !result.data.medicineNotebook2.url &&
      !result.data.medicineNotebook3.url &&
      !result.data.medicineNotebook4.url &&
      !result.data.medicineNotebook5.url &&
      !result.data.medicineNotebook6.url
    ) {
      setUserNotificationMessage(Messages.medicineNotebookUnregistered);
    }
  };

  return {
    isUserInfoRegistered,
    userErrorMessage,
    setUserErrorMessage,
    userNotificationMessage,
    setUserNotificationMessage,
  };
};
