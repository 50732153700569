import { createContext, useState, useContext, ReactNode } from "react";
import Cookies from "js-cookie";
import { AxiosRequestConfig, AxiosHeaders, AxiosError } from "axios";

import {
  signIn as apiSignIn,
  signUp as apiSignUp,
  signOut as apiSignOut,
  passwordReset as apiPasswordReset,
  passwordUpdate as apiPasswordUpdate,
  passwordUpdateFromPrevious as apiPasswordUpdateFromPrevious,
  getCurrentUser as apiGetCurrentUser,
} from "lib/api/auth";
import {
  User,
  SignInParams,
  SignUpParams,
  PasswordUpdateParams,
} from "interfaces/index";
import { PasswordResetFormData } from "../components/schema/PasswordResetSchema";
import { PasswordEditFormData } from "../components/schema/PasswordUpdateSchema";

export type AuthUserContextType = {
  authUser: User | null;
  signUp: (params: SignUpParams, callback: () => void) => Promise<void>;
  signIn: (
    params: SignInParams,
    callback: () => void,
    errorCallback: () => void,
  ) => Promise<void>;
  signOut: (callback: () => void) => void;
  passwordReset: (
    params: PasswordResetFormData,
    callback: () => void,
    errorCallback: () => void,
  ) => Promise<void>;
  passwordUpdate: (
    params: PasswordUpdateParams,
    options: AxiosRequestConfig,
    callback: () => void,
  ) => Promise<void>;
  passwordUpdateFromPrevious: (
    params: PasswordEditFormData,
    options: AxiosRequestConfig,
    callback: () => void,
    errorCallback: () => void,
  ) => Promise<void>;
  getCurrentUser: () => Promise<boolean>;
};
const AuthUserContext = createContext<AuthUserContextType>(
  {} as AuthUserContextType,
);

export const useAuthUserContext = (): AuthUserContextType =>
  useContext<AuthUserContextType>(AuthUserContext);

type Props = {
  children: ReactNode;
};

export const AuthUserProvider = (props: Props) => {
  const [authUser, setAuthUser] = useState<User | null>(null);

  const signUp = async (params: SignUpParams, callback: () => void) => {
    try {
      const { data, headers } = (await apiSignUp(params)) as {
        data: { data: User; status: string };
        headers: { [key: string]: string };
      };
      if (data.status === "success") {
        const user = data.data;
        setAuthUser(user);

        // ログインに成功した場合はCookieに各値を格納
        Cookies.set("_u_access_token", headers["access-token"]);
        Cookies.set("_u_client", headers["client"]);
        Cookies.set("_u_uid", headers["uid"]);

        callback();
      }
    } catch (error) {
      console.log("サインアップエラー");
    }
  };

  const signIn = async (
    params: SignInParams,
    callback: () => void,
    errorCallback: () => void,
  ) => {
    try {
      const { data, headers } = (await apiSignIn(params)) as {
        data: { data: User };
        headers: { [key: string]: string };
      };
      const user = data.data;
      setAuthUser(user);

      // ログインに成功した場合はCookieに各値を格納
      Cookies.set("_u_access_token", headers["access-token"]);
      Cookies.set("_u_client", headers["client"]);
      Cookies.set("_u_uid", headers["uid"]);

      callback();
    } catch (error) {
      console.log("サインインエラー");
      errorCallback();
    }
  };

  const signOut = async (callback: () => void) => {
    try {
      const res = (await apiSignOut()) as { success: boolean };
      if (res.success) {
        setAuthUser(null);
        callback();
      }
    } catch (error) {
      console.log("サインアウトエラー");
    }
  };

  const passwordReset = async (
    params: PasswordResetFormData,
    callback: () => void,
    errorCallback: () => void,
  ) => {
    try {
      const res = (await apiPasswordReset(params)) as {
        success: boolean;
        message: string;
      };
      if (res.success) {
        callback();
      }
    } catch (error) {
      console.log("パスワードリセットエラー");
      errorCallback();
    }
  };

  const passwordUpdate = async (
    params: PasswordUpdateParams,
    options: AxiosRequestConfig,
    callback: () => void,
  ) => {
    try {
      const res = (await apiPasswordUpdate(params, options)) as {
        success: boolean;
        data: User;
        message: string;
      };
      if (res.success) {
        callback();
      }
    } catch (error: any) {
      console.log("パスワード更新エラー(#1)");
    }
  };

  const passwordUpdateFromPrevious = async (
    params: PasswordEditFormData,
    options: AxiosRequestConfig,
    callback: () => void,
    errorCallback: () => void,
  ) => {
    try {
      const res = (await apiPasswordUpdateFromPrevious(params, options)) as {
        success: boolean;
        data: User;
        message: string;
      };
      if (res.success) {
        callback();
      }
    } catch (error: any) {
      console.log("パスワード更新エラー(#2)");
      errorCallback();
    }
  };

  const getCurrentUser = async () => {
    try {
      const res = await apiGetCurrentUser();
      if (res?.isLogin === true) {
        setAuthUser(res?.data);
        return true;
      } else {
        console.log("No current user");
      }
      return false;
    } catch (error) {
      console.log("エラー");
      return false;
    }
  };

  const value: AuthUserContextType = {
    authUser,
    signUp,
    signIn,
    signOut,
    passwordReset,
    passwordUpdate,
    passwordUpdateFromPrevious,
    getCurrentUser,
  };

  return (
    <AuthUserContext.Provider value={value}>
      {props.children}
    </AuthUserContext.Provider>
  );
};
