import { useLayoutEffect } from "react";

const NotFound = () => {
  useLayoutEffect(() => {
    // cf. https://developers.google.com/search/docs/crawling-indexing/javascript/javascript-seo-basics?hl=ja#use-meta-robots-tags-carefully
    let metaRobots = document.querySelector('meta[name="robots"]');
    // if there was no robots meta tag, add one
    if (!metaRobots) {
      metaRobots = document.createElement("meta");
      metaRobots.setAttribute("name", "robots");
      document.head.appendChild(metaRobots);
    }
    // tell Google to exclude this page from the index
    metaRobots.setAttribute("content", "noindex");
  }, []);

  return (
    <>
      <h2>404</h2>
      <h3>このページは存在しません</h3>
    </>
  );
};

export default NotFound;
