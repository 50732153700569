import { useState } from "react";
import {
  Button,
  Stack,
  Grid,
  TextField,
  Link,
  Typography,
  AlertColor,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PasswordUpdateFormData,
  PasswordUpdateSchema,
} from "./schema/PasswordUpdateSchema";

import { Snackbar } from "./parts/Snackbar";
import { ButtonCaption } from "../utils/Constants";

import { styles } from "./style/commonStyles";
import LayoutWithMenu from "./LayoutWithMenu";
import { HeadlineWithLogo } from "./parts/HeadlineWithLogo";

import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useAuthUserContext } from "../contexts/AuthUserContext";

const PasswordUpdate = () => {
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const headers = {
    "access-token": searchParams.get("access-token"),
    client: searchParams.get("client"),
    uid: searchParams.get("uid"),
  };

  const passwordUpdate = useAuthUserContext().passwordUpdate;

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");

  const [updated, setUpdated] = useState(false);

  const onSubmit: SubmitHandler<PasswordUpdateFormData> = (data) => {
    const password = data.password;
    const password_confirmation = data.password_confirmation;
    if (!(password && password_confirmation)) {
      console.log(
        "TODO: validation実装: password or password_confirmation is empty!",
      );
      return false;
    }
    const params: PasswordUpdateFormData = { password, password_confirmation };

    passwordUpdate(params, { headers }, () => {
      setSnackbarProps("success", "パスワードが更新されました", true);
      setUpdated(true);
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordUpdateFormData>({
    resolver: yupResolver(PasswordUpdateSchema),
  });

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (
    severity: AlertColor,
    message: string,
    isOpen: true,
  ) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  };

  return (
    <LayoutWithMenu hideNavLinks={true}>
      <HeadlineWithLogo pageTitleName="パスワードの再設定" />
      {updated ? (
        <>
          <Stack sx={{ align: "center" }}>
            <Typography sx={{ m: 3 }} variant="subtitle1" align="center">
              パスワードを更新しました。
            </Typography>
            <Link
              component={RouterLink}
              to="/login"
              variant="body1"
              align="center"
            >
              ログインページへ
            </Link>
          </Stack>
        </>
      ) : (
        <>
          <Typography sx={{ m: 3, mb: 2 }} variant="subtitle1" align="center">
            新しいパスワードを登録してください。
          </Typography>
          <Stack spacing={6} sx={{ pt: 3 }}>
            <TextField
              required
              label="パスワード"
              type="password"
              InputLabelProps={{ shrink: true }}
              {...register("password")}
              error={"password" in errors}
              helperText={errors.password?.message}
              placeholder="パスワードを入力してください"
              sx={styles.TextField}
            />

            <TextField
              required
              label="パスワード（確認）"
              type="password"
              InputLabelProps={{ shrink: true }}
              {...register("password_confirmation")}
              error={"password_confirmation" in errors}
              helperText={errors.password_confirmation?.message}
              placeholder="パスワードを入力してください"
              sx={styles.TextField}
            />

            <Grid
              container
              alignItems="center"
              justifyContent="space-around"
              direction="row"
            >
              <Grid item xs={5}>
                <Button
                  sx={styles.buttonOutlined}
                  variant="outlined"
                  onClick={() => navigate("/login")}
                >
                  {ButtonCaption.cancel}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  sx={styles.buttonSimple}
                  onClick={handleSubmit(onSubmit)}
                >
                  {ButtonCaption.send}
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </>
      )}
      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
};
export default PasswordUpdate;
