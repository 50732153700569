import { Document, Page } from "react-pdf";
import React from "react";
import { Box } from "@mui/material";

interface Props {
  filePath: string;
}

export function PDFThumbnail(props: Props): React.ReactElement {
  return (
    <Box>
      <Document
        file={props.filePath}
        onLoadError={(error) => console.error("Error loading PDF:", error)}
      >
        {/* 最初のページをサムネイルとして表示 */}
        <Page pageNumber={1} width={150} />
      </Document>
    </Box>
  );
}
