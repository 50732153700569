export const Sex = {
  male: 1,
  female: 2,
  other: 3,
};

export const ButtonCaption = {
  register: "登録する",
  edit: "登録情報を変更する",
  editShort: "変更",
  update: "更新する",
  delete: "削除",
  back: "戻る",
  reservation: "予約する",
  reservationDetail: "診療明細",
  reservationPrescription: "処方せん",
  signin: "ログインする",
  signup: "新規登録する",
  send: "送信する",
  cancel: "キャンセルする",
  billing: "請求",
  show: "詳細",
  create: "作成する",
  completed: "完了にする",
};

export const FacilityType = {
  clinic: "Clinic",
  pharmacy: "Pharmacy",
};

export const FacilityTypeCode = {
  clinic: 1,
  pharmacy: 2,
};

export const ReservationStatus = {
  Completed: 2,
  UnCompleted: 1,
};

export const PrescribeStatus = {
  Prescribed: 2,
  UnPrescribed: 1,
};

export const Messages = {
  userUnregistered:
    "[基本情報]メニュー より、ユーザー情報を登録してください。\nユーザー情報の登録が完了するまで、予約はできません。",
  hicardUnregistered: "[保険証登録]メニュー より、保険証を登録してください。",
  medicineNotebookUnregistered:
    "※服用中・使用中のお薬がある方でお薬手帳をお持ちの方は、[お薬手帳]メニューより、お薬手帳の内容を画像登録してください。",
  userUnregisteredShort:
    "[基本情報]メニュー より、ユーザー情報を登録してください。",
  moveExternalSiteConfirm: "外部の予約サイトへ遷移します。よろしいですか。",
  cannotReservation: "現在、予約できません。管理者にお問合せください。",
  pharmacyReservationNotice: `外部の予約サイトへ移動します。
  服薬指導は病院から薬局への処方せん送信等の時間を考慮し、診療終了後から１時間以上空けて予約してください。
  \n例）
  診療終了時刻: 11:00
  服薬指導の予約時刻: 12:00以降`,
};
