import { useAuthAdminUserContext } from "../../contexts/AuthAdminUserContext";

import { Typography, Button, CssBaseline } from "@mui/material";

import { useAuthUserContext } from "../../contexts/AuthUserContext";

const ClinicStaffEdit = () => {
  const authAdminUser = useAuthAdminUserContext().authAdminUser;

  return (
    <>
      <CssBaseline />
      <Typography variant="h1">FacilityStaffEdit</Typography>
      <p>{authAdminUser && `管理者：${authAdminUser.email}`}</p>
    </>
  );
};

export default ClinicStaffEdit;
