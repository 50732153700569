import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { UserFormData } from "../schema/UserSchema";
import LayoutWithMenu from "../LayoutWithMenu";
import UserDetailCommon from "../UserDetailCommon";
import { getUserDetail } from "../../lib/api/user";
import { useAuthUserContext } from "../../contexts/AuthUserContext";

const UserDetailAdmin = () => {
  const authUser = useAuthUserContext().authUser;
  const navigation = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const userId = searchParams.get("user_id") ?? "";
  console.log({ userId });

  const [formData, setFormData] = useState<UserFormData>(new UserFormData());

  useEffect(() => {
    displayData();
  }, []);

  //　表示内容取得＆設定
  const displayData = async () => {
    if (userId) {
      const result = await getUserDetail(userId);
      console.log({ result });
      if (result.status === "SUCCESS") {
        const data = result.data;
        setFormData({
          name: data.name,
          kana: data.kana,
          sex: data.sex,
          birth: data.birth,
          postalCode: data.postalCode,
          prefecture: data.prefecture,
          address1: data.address1,
          address2: data.address2,
          address3: data.address3,
          phone: data.phone,
        } as UserFormData);
      }
    }
  };

  // 編集画面遷移
  const moveToEdit = () => {
    navigation(`edit`);
  };

  return (
    <LayoutWithMenu pageTitle={"患者情報"}>
      <UserDetailCommon
        formData={formData}
        moveToEdit={moveToEdit}
        editable={false}
      />
    </LayoutWithMenu>
  );
};
export default UserDetailAdmin;
