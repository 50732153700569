export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const randomStrings = (length: number) => {
  return Math.random().toString(36).slice(-length);
};

// backendから渡ってきたdatetimeを"2023年12月19日 (月) 13:00"の形式にformatする
export const formattedDatetime = (datetime: Date) => {
  return new Intl.DateTimeFormat("ja-JP", {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "narrow",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  }).format(new Date(datetime));
};

// アップロードファイル名をランダムな文字列に変換する
export const randomFileName = (name: string) => {
  // 拡張子取り出し
  const ppos = name.lastIndexOf(".");
  const prefix = ppos > 0 ? name.slice(ppos) : "";
  return (
    btoa(
      new Date().getTime().toString(16) +
        Math.floor(1000 * Math.random()).toString(16),
    ) + prefix
  );
};
