import { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
// import { useAuthUserContext } from '../contexts/AuthUserContext'
import { useAuthFacilityStaffContext } from "../../contexts/AuthFacilityStaffContext";

const AuthenticatedFacilityStaff = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authFacilityStaff = useAuthFacilityStaffContext().authFacilityStaff;
  const getCurrentUser = useAuthFacilityStaffContext().getCurrentUser;
  const [isShowable, setIsShowable] = useState(false);

  useEffect(() => {
    (async () => {
      // stateから認証を確認
      if (authFacilityStaff) {
        setIsShowable(true);
        return;
      }
      // cookieから認証を確認
      const res = await getCurrentUser();
      if (res) {
        setIsShowable(true);
        return;
      }
      // ログインしていない場合はloginページへリダイレクト
      //  - ログイン後に復帰するURLを渡す
      navigate(`/facility/login?prev_url=${encodeURI(location.pathname)}`);
    })();
  }, []);

  return <>{isShowable && <Outlet />}</>;
};
export default AuthenticatedFacilityStaff;
