import client from "lib/api/client";
import Cookies from "js-cookie";
import { AxiosRequestConfig, AxiosHeaders } from "axios";

import {
  SignUpParams,
  SignInParams,
  PasswordUpdateParams,
} from "interfaces/index";
import { PasswordResetFormData } from "../../components/schema/PasswordResetSchema";
import { PasswordEditFormData } from "../../components/schema/PasswordUpdateSchema";

// サインアップ（新規アカウント作成）
export const signUp = (params: SignUpParams) => {
  return client.postRetrieveDataAndHeaders("auth", params);
};

// サインイン（ログイン）
export const signIn = (params: SignInParams) => {
  return client.postRetrieveDataAndHeaders("auth/sign_in", params);
};

// サインアウト（ログアウト）
export const signOut = () => {
  return client.delete("auth/sign_out", {
    headers: {
      "access-token": Cookies.get("_u_access_token"),
      client: Cookies.get("_u_client"),
      uid: Cookies.get("_u_uid"),
    },
  });
};

// パスワードリセット
export const passwordReset = (params: PasswordResetFormData) => {
  return client.post("auth/password", params);
};

// パスワード更新
export const passwordUpdate = (
  params: PasswordUpdateParams,
  options: AxiosRequestConfig,
) => {
  return client.put("auth/password", params, options);
};

// パスワード更新（ログイン後画面から）
export const passwordUpdateFromPrevious = (
  params: PasswordEditFormData,
  options: AxiosRequestConfig,
) => {
  return client.post("auth/password_extensions/update_from_previous", params, {
    headers: {
      "access-token": Cookies.get("_u_access_token"),
      client: Cookies.get("_u_client"),
      uid: Cookies.get("_u_uid"),
    },
  });
};

// 認証済みのユーザーを取得
export const getCurrentUser = () => {
  if (
    !Cookies.get("_u_access_token") ||
    !Cookies.get("_u_client") ||
    !Cookies.get("_u_uid")
  )
    return;
  return client.get("/auth/sessions", {
    headers: {
      "access-token": Cookies.get("_u_access_token"),
      client: Cookies.get("_u_client"),
      uid: Cookies.get("_u_uid"),
    },
  });
};
