import { createTheme } from "@mui/material/styles";

// フォントを設定
const fontFamily = [
  "Roboto",
  "Noto Sans JP",
  "メイリオ",
  "ＭＳ Ｐゴシック",
  "sans-serif",
  // 使用したいフォントを以降に羅列してください
].join(",");

/*****************
 * テーマを設定
 *****************
 */
const theme = createTheme({
  typography: {
    fontFamily: fontFamily, // フォント
    fontSize: 13,
  },
  palette: {
    // Primaryカラーを設定
    primary: {
      light: "#87D1EA",
      main: "#87D1EA",
      dark: "#91d5ec",
      contrastText: "#171C1F",
    },
    // // Secondaryカラーを設定
    // secondary: {
    //   light: '#33eb91',
    //   main: '#00e676',
    //   dark: '#00a152',
    //   contrastText: '#ffffff',
    // },
    success: {
      light: "#dee8ec",
      main: "#B3CAD4",
      dark: "#90c4da",
      contrastText: "#1D333B",
    },
    mode: "light", // ダークモードをON
  },
  mixins: {
    // ツールバーの高さ
    toolbar: {
      minHeight: 64,
    },
  },
  // // 各パーツのスタイルをカスタマイズ
  // props: {
  //   MuiCheckbox: {
  //     color: 'primary',
  //   },
  //   MuiList: {
  //     dense: true,
  //   },
  //   MuiTable: {
  //     size: 'small',
  //   },
  //   MuiTextField: {
  //     variant: 'outlined',
  //   },
  // },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        size: "large",
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
        color: "inherit",
      },
    },
  },
});

export default theme;
