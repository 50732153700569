import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthFacilityStaffContext } from "../../contexts/AuthFacilityStaffContext";

const FacilitySignOut = () => {
  const navigate = useNavigate();
  const signOut = useAuthFacilityStaffContext().signOut;

  useEffect(() => {
    signOut(() => {
      // console.log('ログアウト!')
      navigate("/facility/login");
    });
  }, []);

  return <></>;
};
export default FacilitySignOut;
