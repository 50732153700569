import { createContext, useState, useContext, ReactNode } from "react";
import Cookies from "js-cookie";
import { AxiosRequestConfig, AxiosHeaders, AxiosError } from "axios";

import {
  signIn as apiSignIn,
  signUp as apiSignUp,
  signOut as apiSignOut,
  passwordReset as apiPasswordReset,
  passwordUpdate as apiPasswordUpdate,
  getCurrentUser as apiGetCurrentUser,
} from "lib/api/admin_user_auth";
import {
  User,
  SignInParams,
  SignUpParams,
  PasswordResetParams,
  PasswordUpdateParams,
} from "interfaces/index";
import {
  PasswordResetFormData,
  PasswordResetSchema,
} from "../components/schema/PasswordResetSchema";

export type AuthAdminUserContextType = {
  authAdminUser: User | null;
  signUp: (params: SignUpParams, callback: () => void) => Promise<void>;
  signIn: (
    params: SignInParams,
    callback: (adminUser: any) => void,
    errorCallback: () => void,
  ) => Promise<void>;
  signOut: (callback: () => void) => void;
  passwordReset: (
    params: PasswordResetFormData,
    callback: () => void,
    errorCallback: () => void,
  ) => Promise<void>;
  passwordUpdate: (
    params: PasswordUpdateParams,
    options: AxiosRequestConfig,
    callback: () => void,
  ) => Promise<void>;
  getCurrentUser: () => Promise<boolean>;
};
const AuthAdminUserContext = createContext<AuthAdminUserContextType>(
  {} as AuthAdminUserContextType,
);

export const useAuthAdminUserContext = (): AuthAdminUserContextType =>
  useContext<AuthAdminUserContextType>(AuthAdminUserContext);

type Props = {
  children: ReactNode;
};

export const AuthAdminUserProvider = (props: Props) => {
  const [authAdminUser, setAuthAdminUser] = useState<User | null>(null);

  const signUp = async (params: SignUpParams, callback: () => void) => {
    try {
      const { data, headers } = (await apiSignUp(params)) as {
        data: { data: User; status: string };
        headers: { [key: string]: string };
      };
      console.log({ data });
      if (data.status === "success") {
        const user = data.data;
        // console.log("サインアップ成功")
        setAuthAdminUser(user);

        // ログインに成功した場合はCookieに各値を格納
        Cookies.set("_a_access_token", headers["access-token"]);
        Cookies.set("_a_client", headers["client"]);
        Cookies.set("_a_uid", headers["uid"]);

        callback();
      }
    } catch (error) {
      console.log("サインアップエラー");
      console.log(error);
    }
  };

  const signIn = async (
    params: SignInParams,
    callback: (adminUser: any) => void,
    errorCallback: () => void,
  ) => {
    try {
      // console.log('サインイン')
      // console.log({params})
      const { data, headers } = (await apiSignIn(params)) as {
        data: { data: User; message: string };
        headers: { [key: string]: string };
      };

      if (data.message === "require OTP") {
        // 呼び出し側でワンタイムパスワード認証ステップに進む
        callback(data.message);
        return;
      }

      const adminUser = data.data;
      // console.log({user, headers})
      setAuthAdminUser(adminUser);

      // ログインに成功した場合はCookieに各値を格納
      Cookies.set("_a_access_token", headers["access-token"]);
      Cookies.set("_a_client", headers["client"]);
      Cookies.set("_a_uid", headers["uid"]);

      callback(adminUser);
    } catch (error) {
      // console.log("サインインエラー")
      console.log(error);
      errorCallback();
    }
  };

  const signOut = async (callback: () => void) => {
    try {
      const res = (await apiSignOut()) as { success: boolean };
      if (res.success) {
        // console.log("サインアウト成功")
        setAuthAdminUser(null);
        callback();
      }
    } catch (error) {
      console.log("サインアウトエラー");
      console.log(error);
    }
  };

  const passwordReset = async (
    params: PasswordResetFormData,
    callback: () => void,
    errorCallback: () => void,
  ) => {
    try {
      const res = (await apiPasswordReset(params)) as {
        success: boolean;
        message: string;
      };
      // console.log({res})
      if (res.success) {
        callback();
      }
    } catch (error) {
      // console.log("パスワードリセットエラー")
      console.log(error);
      errorCallback();
    }
  };

  const passwordUpdate = async (
    params: PasswordUpdateParams,
    options: AxiosRequestConfig,
    callback: () => void,
  ) => {
    try {
      const res = (await apiPasswordUpdate(params, options)) as {
        success: boolean;
        data: User;
        message: string;
      };
      console.log({ res });
      if (res.success) {
        // console.log("パスワード更新成功")
        callback();
      }
    } catch (error: any) {
      console.log("パスワード更新エラー");
      console.log(error);
      // const errorMessage: string = error.response.data.errors.fullMessages
    }
  };

  const getCurrentUser = async () => {
    try {
      const res = await apiGetCurrentUser();
      // console.log('getCurrentUser', {res})
      if (res?.isLogin === true) {
        setAuthAdminUser(res?.data);
        return true;
      } else {
        console.log("No current user");
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const value: AuthAdminUserContextType = {
    authAdminUser,
    signUp,
    signIn,
    signOut,
    passwordReset,
    passwordUpdate,
    getCurrentUser,
  };

  return (
    <AuthAdminUserContext.Provider value={value}>
      {props.children}
    </AuthAdminUserContext.Provider>
  );
};
