import React from "react";
import { Button, Grid, Box, Typography } from "@mui/material";

import { UserFormData } from "./schema/UserSchema";
import { ButtonCaption, Sex } from "../utils/Constants";

import {
  PersonOutlineOutlined as PersonIcon,
  WcOutlined as UnisexIcon,
  CalendarTodayOutlined as CalendarIcon,
  HouseOutlined as HouseIcon,
  Phone as PhoneIcon,
} from "@mui/icons-material";

const buttonStyle = {
  backgroundColor: "#21bddb",
  boxShadow: "none",
  fontSize: { xs: "0.75rem", sm: "0.875rem" },
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "#1aa3c9",
    boxShadow: "none",
  },
  width: "200px",
  margin: "0",
};

const customStyles = {
  gridContainer: {
    borderBottom: "1px solid lightGrey",
    fontSize: 14,
    textDecoration: "none",
    color: "#000000",
    py: 1,
    backgroundColor: "#F5FAFD !important",
    paddingTop: 1,
    paddingBottom: 1,
    alignItems: "center", // 行の高さを揃えるために追加
    marginTop: 0,
    marginLeft: "0 !important",
  },
  gridItem: {
    alignContent: "center",
    backgroundColor: "#F5FAFD !important",
    display: "flex", // アイテムを中央揃えにするために追加
    alignItems: "center", // アイテムを中央揃えにするために追加
    paddingLeft: "0 !important",
  },
  boldLabel: {
    fontWeight: 600, // 項目名を太字にする
  },
  container: {
    width: "100%",
    paddingLeft: "24px",
    paddingRight: "24px",
    textAlign: "center !important",
  },
  icon: {
    width: "20px",
    height: "20px",
    marginRight: "0px",
    color: "#32AFDA",
    paddingLeft: "5px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10%", // ボタン間の間隔を設定
    width: "100%",
    marginTop: "30px",
  },
};

// 各行に表示するアイコンのリストを作成
const iconsList = [PersonIcon, UnisexIcon, CalendarIcon, HouseIcon, PhoneIcon];

type Props = {
  formData: UserFormData;
  moveToEdit: () => void;
  editable: boolean;
};

const UserDetailCommon: React.FC<Props> = (props) => {
  const { formData, moveToEdit, editable } = props;

  // 詳細項目Grid
  const gridItemWithLabel = (
    label: string,
    value: string | undefined,
    IconComponent: React.ComponentType,
  ) => {
    return (
      <Grid
        container
        sx={[customStyles.gridContainer, { marginTop: "0", paddingTop: "0" }]}
        spacing={2}
      >
        <Grid item xs={1} sx={[customStyles.gridItem, { paddingLeft: "5px" }]}>
          <Box component={IconComponent} sx={customStyles.icon} />
        </Grid>
        <Grid item xs={3} sx={customStyles.gridItem}>
          <Typography sx={customStyles.boldLabel}>{label}</Typography>
        </Grid>
        <Grid item xs={8} sx={customStyles.gridItem}>
          {value}
        </Grid>
      </Grid>
    );
  };

  // 「性別」を文字列で返す
  const sexToString = (sex: number) => {
    switch (sex) {
      case Sex.male:
        return "男性";
      case Sex.female:
        return "女性";
      default:
        return "その他・未回答";
    }
  };

  // 「住所」文字列を構築する
  const addressStr = (formData: UserFormData) => {
    return `〒${formData.postalCode ?? ""}\n${formData.prefecture ?? ""}${formData.address1 ?? ""}${formData.address2 ?? ""}${formData.address3 ?? ""}`;
  };

  // 電話番号の登録がない場合「未登録」の文字列を表示する
  const phoneNumberStr = (phone: string | undefined) => {
    if (phone === undefined || phone === "") return "未登録";
    return phone;
  };

  return (
    <>
      {gridItemWithLabel("氏名", formData.name, iconsList[0])}
      {gridItemWithLabel("かな", formData.kana, iconsList[0])}
      {gridItemWithLabel("性別", sexToString(formData.sex), iconsList[1])}
      {gridItemWithLabel("生年月日", formData.birth?.toString(), iconsList[2])}
      {gridItemWithLabel("住所", addressStr(formData), iconsList[3])}
      {gridItemWithLabel(
        "電話番号",
        phoneNumberStr(formData.phone),
        iconsList[4],
      )}

      <Box sx={customStyles.buttonContainer}>
        {editable && (
          <Button
            sx={buttonStyle}
            color="primary"
            variant="contained"
            onClick={moveToEdit}
          >
            {ButtonCaption.edit}
          </Button>
        )}
      </Box>
    </>
  );
};
export default UserDetailCommon;
