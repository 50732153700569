import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Grid, Button, AlertColor, Stack } from "@mui/material";

import {
  getPrescriptionByClinicReservation,
  createPrescriptions,
  updatePrescriptions,
  deletePrescriptionFile,
  getReservationWithFacility,
} from "../../lib/api/reservation";

import LayoutWithMenu from "../LayoutWithMenu";

import { styles as commonStyles } from "../style/commonStyles";
import CustomDialog from "../parts/CustomDialog";
import FileUploader from "../parts/FileUploader";
import { Snackbar } from "../parts/Snackbar";
import { ButtonCaption } from "../../utils/Constants";
import { randomFileName } from "../../utils/helper";
import { Reservation } from "interfaces";
import { useHistoryContext } from "contexts/HistoryContext";

const styles = {
  ...commonStyles,
  PrescriptionBoxStyle: {
    mt: 2,
    py: 1,
    border: 1,
    borderColor: "lightGrey",
  },
  prescriptionSendButton: {
    ...commonStyles.buttonSimple,
    width: "328px",
    height: "56px",
  },
  prescriptionBackButton: {
    ...commonStyles.buttonOutlined,
    width: "322px",
  },
};

class PrescriptionFormData {
  id: number = 0;
  user_id: number = 0;
  clinic_reservation_id: number = 0;
  pharmacy_id: number = 0;
  prescription_files: File[] = [];
  status: number = 0;
}

const PrescriptionSending = () => {
  // 遷移パラメータ
  const navigation = useNavigate();
  const { reservation_id: clinicReservationId } = useParams<{
    reservation_id: string;
  }>();
  const searchParams = new URLSearchParams(window.location.search);
  const relationReservePharmacyId = searchParams.get("rel_reserve_id");
  const [reservation, setReservation] = useState<Reservation>();
  const [prescription, setPrescription] = useState<PrescriptionFormData>(
    new PrescriptionFormData(),
  );
  const [prescriptionFiles, setPrescriptionFiles] = useState<File[]>([]);
  const [prescriptionURLs, setPrescriptionURLs] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");
  const { previousPage } = useHistoryContext();
  const canGoBack = previousPage !== null;

  // dialog用
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<string>("");
  const [isHiddenCancel, setIsHiddenCancel] = useState<boolean>(false);
  const [handleOnConfirm, setHandleOnConfirm] = useState<() => void>(() => {});

  useEffect(() => {
    // パラメータチェック:必要なパラメータが空の場合、予約一覧から処理をやり直す
    if (!clinicReservationId || !relationReservePharmacyId) {
      setDialogContent(
        "処理が正しく行われませんでした。最初からやり直してください",
      );
      setIsHiddenCancel(true);
      setHandleOnConfirm(() => moveToDetail);
      setIsDialogOpen(true);
      return;
    }
    // 初期表示データ設定
    displayData(Number(clinicReservationId));
  }, []);

  // 予約一覧画面遷移
  const moveToDetail = () => {
    navigation(`/facility/reservation`);
  };

  //　初期データ表示内容取得＆設定
  const displayData = async (clinicReservationId: number) => {
    await fetchReservation(clinicReservationId);
    await getPrescriptionData();
  };

  // 処方せん情報取得
  const getPrescriptionData = async () => {
    try {
      const result = await getPrescriptionByClinicReservation(
        Number(clinicReservationId),
      );
      setPrescription(result.data);
      if (result.data) {
        setPrescriptionURLs(
          result.data.prescriptionFiles.map((data: any) => {
            return data?.url;
          }),
        );
      }
    } catch (error) {
      console.log(error);
      setSnackbarProps("error", "処方せん情報の取得に失敗しました", true);
    }
  };

  // 初期表示
  const fetchReservation = async (reservationId: number) => {
    try {
      const response = await getReservationWithFacility(
        reservationId.toString(),
      );
      setReservation(response.data);
    } catch (error) {
      console.error("予約の取得に失敗しました", error);
    }
  };

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (
    severity: AlertColor,
    message: string,
    isOpen: true,
  ) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  };

  // 処方せん情報登録実行
  const sendPrescription = async () => {
    if (!clinicReservationId || !relationReservePharmacyId || !reservation) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("prescription[user_id]", reservation?.userId.toString());
      formData.append(
        "prescription[clinic_reservation_id]",
        clinicReservationId,
      );
      formData.append(
        "prescription[pharmacy_reservation_id]",
        relationReservePharmacyId,
      );
      // 複数画像の登録
      prescriptionFiles.forEach((file) => {
        formData.append(
          "prescription[prescription_files][]",
          file,
          randomFileName(file.name),
        );
      });

      // 処方せん送信実行
      if (prescription && prescription.id) {
        await updatePrescriptions(prescription.id, formData);
      } else {
        await createPrescriptions(formData);
      }
      // 情報の際取得と画像クリア
      await getPrescriptionData();
      setSnackbarProps("success", "処方せんが正常に送信されました", true);
    } catch (error) {
      console.error(error);
      setSnackbarProps("error", "処方せんの送信に失敗しました", true);
    } finally {
      // 画像クリアはスナックバーの表示後に行う
      setPrescriptionFiles([]);
    }
  };

  const handleDeleteFile = async (fileName: string) => {
    try {
      await deletePrescriptionFile(prescription.id, fileName);
      // 削除に成功したら、表示されているファイルリストから削除
      const updatedURLs = prescriptionURLs.filter(
        (url) => !url.includes(fileName),
      );
      setPrescriptionURLs(updatedURLs);
      setSnackbarProps("success", "処方せん画像が削除されました", true);
    } catch (error) {
      console.error(error);
      setSnackbarProps("error", "処方せん画像の削除に失敗しました", true);
    }
  };

  return (
    <LayoutWithMenu pageTitle={`処方せん`}>
      <Stack spacing={2} sx={{ pt: 3 }}>
        <FileUploader
          files={prescriptionFiles}
          setImages={setPrescriptionFiles}
          isSingleSelect={false}
          imagesURL={prescriptionURLs}
          setImagesURL={setPrescriptionURLs}
          onDeleteFile={handleDeleteFile}
        />
        <Stack spacing={3}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <Grid item xs={12}>
              <Button
                color="primary"
                disabled={prescriptionFiles.length === 0}
                sx={styles.prescriptionSendButton}
                onClick={() => sendPrescription()}
              >
                {ButtonCaption.send}
              </Button>
            </Grid>
          </Grid>

          {canGoBack && (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              <Grid item xs={12}>
                <Button
                  color="secondary"
                  variant="outlined"
                  sx={styles.prescriptionBackButton}
                  onClick={() => navigation(-1)}
                >
                  {ButtonCaption.back}
                </Button>
              </Grid>
            </Grid>
          )}
        </Stack>
      </Stack>

      <CustomDialog
        isOpen={isDialogOpen}
        content={dialogContent}
        isHiddenCancel={isHiddenCancel}
        onConfirm={handleOnConfirm}
        onCancel={() => setIsDialogOpen(false)}
      />

      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
};
export default PrescriptionSending;
