// 住所項目の整形
export const zipAndAddress = (
  zip: string | undefined | null,
  address: string | undefined,
) => {
  let addressStr = "";
  if (zip) addressStr += `〒${zip} `;
  if (address) addressStr += address;
  return addressStr;
};
