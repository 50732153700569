import client from "lib/api/client";
import { FacilityNewFormData } from "../../components/schema/FacilitySchema";

// 薬局一覧取得
export const getPharmacyList = () => {
  return client.get(`pharmacy`);
};

// 薬局詳細取得
export const getPharmacyDetail = (id: string) => {
  return client.get(`pharmacy/${id}`);
};

// 薬局新規登録
export const newPharmacy = (data: FacilityNewFormData) => {
  return client.post(`pharmacy`, data);
};
