import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

type HistoryContextType = {
  previousPage: string | null;
  currentPage: string | null;
  hasPreviousPage: boolean;
};

const HistoryContext = createContext<HistoryContextType | undefined>(undefined);

export const HistoryProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const [previousPage, setPreviousPage] = useState<string | null>(() => {
    return sessionStorage.getItem("previousPage");
  });
  const [currentPage, setCurrentPage] = useState<string | null>(() => {
    return sessionStorage.getItem("currentPage");
  });

  useEffect(() => {
    const storedCurrentPage = sessionStorage.getItem("currentPage");

    // 初回ロード時は currentPage を sessionStorage に保存し、履歴を更新しない
    if (storedCurrentPage === null) {
      sessionStorage.setItem("currentPage", location.pathname);
      setCurrentPage(location.pathname);
      return;
    }

    // リロード時は currentPage が変化しない場合、履歴を更新しない
    if (storedCurrentPage === location.pathname) {
      return;
    }

    // ページ遷移時のみ履歴を更新
    sessionStorage.setItem("previousPage", storedCurrentPage);
    sessionStorage.setItem("currentPage", location.pathname);

    setPreviousPage(storedCurrentPage);
    setCurrentPage(location.pathname);
  }, [location.pathname]);

  return (
    <HistoryContext.Provider
      value={{
        previousPage,
        currentPage,
        hasPreviousPage: previousPage !== null && previousPage !== "",
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export const useHistoryContext = () => {
  const context = useContext(HistoryContext);
  if (!context) {
    throw new Error("useHistoryContext must be used within a HistoryProvider");
  }
  return context;
};
